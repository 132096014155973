import consts from '../consts';
import { AuthAPI } from '../api/auth.api';
import { StorageService } from './storage.service';

const { STORAGE_ITEM_EMAIL, STORAGE_ITEM_ACCESS_TOKEN, STORAGE_ITEM_MEMBER_ID } = consts;

export class AuthService {
  hasAuth(): boolean {
    return Boolean(StorageService.email && StorageService.token);
  }

  clearAuth(): void {
    StorageService.remove(STORAGE_ITEM_EMAIL);
    StorageService.remove(STORAGE_ITEM_ACCESS_TOKEN);
    StorageService.remove(STORAGE_ITEM_MEMBER_ID);
  }

  async login(data: { email: string; password: string }): Promise<boolean> {
    let res = await AuthAPI.login(data);

    if (!res.success) {
      throw res.error;
    }
    if (!res.result) {
      throw new Error('res.result is empty');
    }
    StorageService.email = res.result.data.email;
    StorageService.token = res.result.data.token;
    StorageService.memberId = res.result.data.memberId;

    return true;
  }

  async logout(): Promise<boolean> {
    let email = StorageService.email;
    let token = StorageService.token;

    await AuthAPI.logout({ email, token });
    this.clearAuth();

    return true;
  }

  async resetPassword(data: { email: string }): Promise<boolean> {
    let res = await AuthAPI.resetPassword(data);

    if (!res.success) {
      throw res.error;
    }
    if (!res.result) {
      throw new Error('res.result is empty');
    }
    StorageService.email = res.result.data.email;

    return true;
  }
}
