import axios, { AxiosRequestConfig } from 'axios';
import configs from '../configs';
import consts from '../consts';
import { StorageService } from '../services/storage.service';

const { HEADER_USER_ACCESS_TOKEN, HEADER_USER_EMAIL } = consts;

export interface APIRequest {
  path: string;
  method: 'GET' | 'POST';
  params?: AxiosRequestConfig['params'];
  data?: AxiosRequestConfig['data'];
}

export interface APIResponseResult<T> {
  data: T;
}

export interface APIResponseError {
  status: number;
  substatus: number;
  type: string;
  code: string;
  message: string;
}

export interface APIResponse<T> {
  success: boolean;
  result?: APIResponseResult<T>;
  error?: APIResponseError;
}

export class BaseAPI {
  static async call<T = any>(request: APIRequest): Promise<APIResponse<T>> {
    try {
      let response = await axios({
        baseURL: configs.apiHost,
        url: request.path,
        method: request.method,
        params: request.params,
        data: request.data,
        headers: {
          [HEADER_USER_EMAIL]: StorageService.email,
          [HEADER_USER_ACCESS_TOKEN]: StorageService.token,
        },
      });

      return response.data;
    } catch (error) {
      if (typeof error.response.data === 'string') {
        return { success: false, error };
      }
      return error.response.data;
    }
  }
}
