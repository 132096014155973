import React from 'react';
import { Row, Col, Descriptions, Divider, Button, Select, Typography } from 'antd';
import { MemberDto } from '../../../transformers/member.dto';
import moment from 'moment';

interface Props {
  memberData: MemberDto;
  currentSnData: any;
  onChangeCCID?: (value?: any) => void;
  onChangeSubscribe?: (value?: any) => void;
}

const SubscribeDataForm: React.FC<Props> = (props) => {
  const { memberData, currentSnData, onChangeCCID, onChangeSubscribe } = props;

  return (
    <Row className="member-form" justify="space-between">
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="訂閱起始日">
            {currentSnData ? moment(currentSnData.startDate).format('YYYY-MM-DD') : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="訂閱截止日">
            {currentSnData ? moment(currentSnData.endDate).format('YYYY-MM-DD') : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="訂閱門市">{currentSnData?.storeName || '-'}</Descriptions.Item>
        </Descriptions>
        <Divider dashed />
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="訂閱單號">{currentSnData?.rsId || '-'}</Descriptions.Item>
          <Descriptions.Item label="訂閱期數">
            <span className="subscribe-info"> {currentSnData?.maxPeriod || '-'} </span> 期
          </Descriptions.Item>
          <Descriptions.Item label="已使用期數">
            <Row justify="space-between">
              <Col span={24}>
                <span className="subscribe-info">
                  {' '}
                  {currentSnData?.currentPeriod || '-'}/{currentSnData?.maxPeriod || '-'}{' '}
                </span>{' '}
                期
              </Col>
              {/* <Col span={1}><Button type="primary" size="small" ghost onClick={() => onChangeSubscribe('24')}>變更</Button></Col */}
            </Row>
          </Descriptions.Item>
        </Descriptions>
        <Divider dashed />
      </Col>
      <Divider className="vertical-divider" type="vertical" />
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="APAP機種">{currentSnData?.apapModelName || '-'}</Descriptions.Item>
          <Descriptions.Item label="面罩種類">{currentSnData?.maskKindName || '-'}</Descriptions.Item>
          <Descriptions.Item label="序號">{currentSnData?.sn || '-'}</Descriptions.Item>
        </Descriptions>
        <Divider dashed />
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="信用卡" className="creditCardInfo">
            <Row justify="start">
              <Col span={18}>
                {currentSnData?.ccdId
                  ? currentSnData.ccdId.substr(0, 6) + '******' + currentSnData.ccdId.substr(12, 4)
                  : '-'}
              </Col>
              <Col span={6}>
                <Button type="primary" size="small" ghost onClick={onChangeCCID}>
                  變更
                </Button>
              </Col>
              <Col span={8}>
                月： {currentSnData && currentSnData.expiredDate && currentSnData.expiredDate.substr(0, 2)}
              </Col>
              <Col span={8}>
                年： {currentSnData && currentSnData.expiredDate && currentSnData.expiredDate.substr(2, 2)}
              </Col>
            </Row>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="扣款日">
            每月
            <span className="subscribe-info">
              {currentSnData && currentSnData.nextPaymentDate && moment(currentSnData.nextPaymentDate).format('DD')}{' '}
            </span>
            日
          </Descriptions.Item>
        </Descriptions>
        <Divider dashed />
      </Col>
    </Row>
  );
};

export default SubscribeDataForm;
