import React, { useState } from 'react';
import { Button, Result } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import Components from '../components';
import { RegisterFormValues } from '../components/form/register.form';
import { MemberService } from '../services/member.service';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const RegisterContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [email, setEamil] = useState('');

  const handleRegisterSubmit = async (values: RegisterFormValues): Promise<void> => {
    try {
      const token = window.location.href.split('token=')[1];
      await MemberService.registerMember({
        ...values,
        token: token,
      });
      setEamil(values.email);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setShowResult(true);
    }
  };

  return (
    <>
      <div className="banner">
        <h1>會員註冊</h1>
      </div>
      <div className="login-container">
        {showResult ? (
          <Result status="success" title={`註冊成功`} subTitle={`請到 E-mail 信箱 ${email} 收取註冊信件`} />
        ) : (
          <Components.Tabs.MainTabs
            tabContent={[
              {
                key: '1',
                title: '會員註冊',
                content: <Components.Form.Register processing={processing} onSubmit={handleRegisterSubmit} />,
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default withBaseContainer(withRouter(RegisterContainer));
