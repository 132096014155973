import React, { useMemo } from 'react';

import { Col, Row, Divider, Progress } from 'antd';
import { Achievement, Prize } from '../../transformers/achievemen.dto';

import './achievement.scss';

const PrizeTextMap: { [K in Prize]: string } = {
  Platinum: '白金',
  Gold: '金',
  Silver: '銀',
  Bronze: '銅',
};
interface Props {
  achievement: Achievement;
}
const AchievementDescCard: React.FC<Props> = (props) => {
  const { achievement } = props;
  const totalScore = useMemo(
    () =>
      achievement.prizeDetail
        ? Object.keys(achievement.prizeDetail).reduce(
            (prev, key) => prev + achievement.prizeDetail![key as Prize].score,
            0
          )
        : 0,
    [achievement]
  );
  return (
    <div className="achievement--popover">
      <div className="name">{achievement.name}</div>
      <div className="mission">
        任務:<span className="mission-content">{achievement.mission}</span>
      </div>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <div className="score">
        積分:<span className="score-value">{achievement.score}</span>XP
      </div>
      <div className="prize">{achievement.prize ? PrizeTextMap[achievement.prize] : ''}</div>
      <div className="score-progress" style={{ marginBottom: 8 }}>
        <Progress strokeColor="#FBC600" type="line" showInfo={false} percent={(achievement.score / totalScore) * 100} />
        <div className="progress-hint">{`${achievement.score} / ${totalScore}`}</div>
      </div>
      <Row className="prize-detail" gutter={8}>
        <Col xs={6} className="prize-detail--content">
          <div className="icon">銅</div>
          {achievement.prizeDetail!['Bronze'].desc}
        </Col>
        <Col xs={6} className="prize-detail--content">
          <div className="icon">銀</div>
          {achievement.prizeDetail!['Silver'].desc}
        </Col>
        <Col xs={6} className="prize-detail--content">
          <div className="icon">金</div>
          {achievement.prizeDetail!['Gold'].desc}
        </Col>
        <Col xs={6} className="prize-detail--content">
          <div className="icon">白金</div>
          {achievement.prizeDetail!['Platinum'].desc}
        </Col>
      </Row>
    </div>
  );
};
export default AchievementDescCard;
