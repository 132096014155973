import React from 'react';
import { Row, Col, Card, Avatar, Divider, Tag, Descriptions, Button, Carousel, Image } from 'antd';
import {
  UserOutlined,
  IdcardOutlined,
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { MemberDto } from '../../transformers/member.dto';

interface Props {
  memberData: MemberDto;
  currentSnData: any;
  onMemberDevice: (value: string) => void;
}

const MemberInfoCard: React.FC<Props> = (props) => {
  const { memberData, currentSnData, onMemberDevice } = props;

  const invoiceTake = (value: any) => {
    switch (value) {
      case '1':
        return '電子發票';
      case '2':
        return '會員載具';
      default:
        return '-';
    }
  };

  const memberType = (value: number) => {
    switch (value) {
      case 2:
        return <Tag color="blue">買斷</Tag>;
      case 3:
        return <Tag color="green">訂閱</Tag>;
      default:
        return '-';
    }
  };

  const announceImages = [
    {
      alt: '會員積分挑戰賽',
      src: '/images/sliders/slider4.jpg',
      link: 'https://resmed.ear.com.tw/news/detail/141',
    },
    {
      alt: '舊客專屬優惠活動',
      src: '/images/sliders/slider5.jpg',
      link: 'https://resmed.ear.com.tw/news/detail/95',
    },
  ];

  return (
    <>
      <Row gutter={16} className="member-card-pad">
        <Col span={7} style={{ padding: 0 }}>
          <Carousel autoplay>
            {announceImages.map((image, index) => {
              return (
                <a href={image.link} key={index} target="_blank" rel="noreferrer">
                  <Image alt={image.alt} src={image.src} style={{ width: '100%' }} />
                </a>
              );
            })}
          </Carousel>
        </Col>
        <Col span={17} style={{ padding: 16 }}>
          <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }} colon={false}>
            <Descriptions.Item label={<IdcardOutlined />}>
              <p>{memberData?.memberId || '-'}</p>
              <Divider type="vertical" />
              {memberData ? invoiceTake(memberData.invoiceTake) : '-'}
              {memberData.rsId && (
                <Button className="btn-account" size="small" onClick={() => onMemberDevice(memberData.rsId)}>
                  歸戶
                </Button>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={<MailOutlined />}>{memberData.email || '-'}</Descriptions.Item>
            <Descriptions.Item label={<MobileOutlined />}>{memberData.mobile || '-'}</Descriptions.Item>
            <Descriptions.Item label={<PhoneOutlined />}>{memberData.phone || '-'}</Descriptions.Item>
            <Descriptions.Item label={<HomeOutlined />}>{memberData.address || '-'}</Descriptions.Item>
            <Descriptions.Item label="統編">{memberData.taxId || '-'}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>

      <Card
        className="member-card"
        cover={
          <Carousel autoplay>
            {announceImages.map((image, index) => {
              return (
                <a href={image.link} key={index} target="_blank" rel="noreferrer">
                  <Image alt={image.alt} src={image.src} style={{ width: '100%' }} />
                </a>
              );
            })}
          </Carousel>
        }
        bodyStyle={{ padding: 16 }}
      >
        <Card.Meta
          avatar={<Avatar icon={<UserOutlined />} style={{ backgroundColor: 'orange' }} />}
          title={
            <Row justify="space-between">
              <Col>{currentSnData.name}</Col>
              <Col>{memberType(currentSnData.memberType)}</Col>
            </Row>
          }
        />
        <Divider />
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label={<IdcardOutlined />}>
            {currentSnData?.memberId || '-'}
            <Divider type="vertical" />
            {memberData ? invoiceTake(currentSnData.invoiceTake) : '-'}
            {currentSnData.rsId && (
              <Button className="btn-account" size="small" onClick={() => onMemberDevice(currentSnData.rsId)}>
                歸戶
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={<MailOutlined />}>{currentSnData.email || '-'}</Descriptions.Item>
          <Descriptions.Item label={<MobileOutlined />}>{currentSnData.mobile || '-'}</Descriptions.Item>
          <Descriptions.Item label={<PhoneOutlined />}>{currentSnData.phone || '-'}</Descriptions.Item>
          <Descriptions.Item label={<HomeOutlined />}>{currentSnData.address || '-'}</Descriptions.Item>
          <Descriptions.Item label="統編">{currentSnData.taxId || '-'}</Descriptions.Item>
        </Descriptions>
        <Row justify="center">
          <Col span={10}>
            <Button href={`/member/${currentSnData?.memberId}/changePassword`} ghost type="primary" size="small">
              設定新的密碼
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default MemberInfoCard;
