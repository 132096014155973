import React from 'react';
import Media from 'react-media';
import { Table, Collapse, Descriptions, Button, Statistic } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { TransactionDetailDto } from '../../transformers/shippingOrder.dto';

interface Props {
  processing?: boolean;
  dataSource?: any[];
  onOpenInvoiceDetail: (value: any) => void;
}

const TransactionTable: React.FC<Props> = (props) => {
  const { dataSource, onOpenInvoiceDetail } = props;

  // const winStatus = (value: any) => {
  //     switch (value) {
  //         case 0:
  //             return '未中獎';
  //         case 1:
  //             return '未開獎';
  //         case 2:
  //             return '中獎';
  //         default:
  //             return '-';
  //     }
  // };
  const columns: ColumnProps<TransactionDetailDto>[] = [
    {
      title: '交易日期',
      dataIndex: 'shippingDate',
      key: 'shippingDate',
      className: 'transaction-cell',
      render: (text: any, record: any) => <span>{moment(record.shippingDate).format('YYYY-MM-DD')}</span>,
    },
    {
      title: '公司別',
      dataIndex: 'companyName',
      key: 'companyName',
      className: 'transaction-cell',
    },
    {
      title: '發票號碼',
      dataIndex: 'invoiceNo',
      key: 'invoiceNoo',
      className: 'transaction-cell',
    },
    {
      title: '金額',
      dataIndex: 'taxedAmount',
      key: 'taxedAmount',
      className: 'transaction-cell',
      render: (text: any, record: any) => <Statistic className="transaction-price" value={record.taxedAmount} />,
    },
    {
      title: '發票明細',
      dataIndex: 'invoiceDetail',
      key: 'invoiceDetail',
      className: 'transaction-cell',
      render: (text: any, record: any) => {
        return record.invoiceNo ? <a onClick={() => record.orderCode && onOpenInvoiceDetail(record.code)}>內容</a> : '';
      },
    },
    {
      title: '統一編號',
      dataIndex: 'customerTaxId',
      key: 'tax',
      className: 'transaction-cell',
    },
    // {
    //     title: '載具',
    //     dataIndex: 'finance',
    //     key: 'finance',
    //     className: 'transaction-cell',
    // },
    // {
    //     title: '中獎',
    //     dataIndex: 'win',
    //     key: 'win',
    //     className: 'transaction-cell',
    //     render: (text: any, record: any) => <span>{winStatus(record.win)}</span>,
    // },
    {
      title: '備註',
      dataIndex: 'paymentMemo',
      key: 'paymentMemo',
      className: 'transaction-cell',
    },
  ];

  return (
    <Media query={{ maxWidth: 576 }}>
      {(matches) =>
        matches ? (
          <Collapse>
            {dataSource &&
              dataSource.map((item, index) => (
                <Collapse.Panel
                  header={item.invoiceNo ? item.invoiceNo : '發票未開'}
                  key={index}
                  extra={
                    item.invoiceNo ? (
                      <Button size="small" onClick={() => item.orderCode && onOpenInvoiceDetail(item.code)}>
                        發票明細
                      </Button>
                    ) : (
                      ''
                    )
                  }
                >
                  <Descriptions>
                    <Descriptions.Item label="交易日期">
                      {moment(item.shippingDate).format('YYYY-MM-DD')}
                    </Descriptions.Item>
                    <Descriptions.Item label="發票號碼">{item.invoiceNo}</Descriptions.Item>
                    <Descriptions.Item label="金額">{item.taxedAmount}</Descriptions.Item>
                    <Descriptions.Item label="統一編號">{item.customerTaxId}</Descriptions.Item>
                    <Descriptions.Item label="載具">-</Descriptions.Item>
                    <Descriptions.Item label="中獎">-</Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
              ))}
          </Collapse>
        ) : (
          <Table
            className="member-transaction-table"
            size="small"
            rowKey={(record) => {
              return record.invoiceNo;
            }}
            columns={columns}
            dataSource={dataSource}
          />
        )
      }
    </Media>
  );
};

export default TransactionTable;
