import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import numeral from 'numeral';

import './rankItem.scss';
import AchievementCard from '../achievement/achievement.card';
import { MemberAchievement } from '../../transformers/achievemen.dto';

interface Props {
  memberAchievement: MemberAchievement;
}
const RankItem: React.FC<Props> = (props) => {
  const { memberAchievement } = props;
  return (
    <Row className="rank-item" style={{ flexWrap: 'nowrap' }} align="middle" gutter={16}>
      <Col flex={0}>
        <div className="rank">-</div>
      </Col>
      <Col xs={10} md={6} xl={5}>
        <div className="name">
          <span className="text">{memberAchievement.member?.name}</span>
          <span className="level-tag">{`LV ${memberAchievement.level}`}</span>
        </div>
        <div className="score">{`${numeral(memberAchievement.score).format('0,0')} XP`}</div>
      </Col>
      <Col flex={1} style={{ overflow: 'auto', display: 'flex', flexDirection: 'row-reverse' }}>
        {memberAchievement.achievements
          ?.filter((achievement) => achievement.prize)
          .map((achievement) => (
            <Tooltip key={achievement.name} title={achievement.name}>
              <div style={{ minWidth: 70, padding: '0 2px' }}>
                <AchievementCard size="small" achievement={achievement} />
              </div>
            </Tooltip>
          ))}
      </Col>
    </Row>
  );
};
export default RankItem;
