export enum EnumResmedStatus {
  Created = '1',
  Closed = '2',
  Abandoned = '9',
}

export enum EnumMemberType {
  'buyingOut' = 2,
  'subscribe' = 3,
}
// 保養配件
export enum EnumCareItem {
  'main' = 1,
  'mask' = 2,
  'tube' = 3,
  'waterBox' = 4,
  'filterCotton' = 5,
}

export interface MemberDto {
  memberId: string;
  name: string;
  phone: string;
  mobile: string;
  email: string;
  postCode: string;
  address: string;
  storeId: string;
  storeName: string;
  legal: string;
  rsId: string;
  period: string;
  periodName: string;
  rsStatus: EnumResmedStatus;
  startDate: Date;
  endDate: Date;
  cancelDate: Date;
  invoiceTake: string;
  taxId: string;
  apapModel: string;
  apapModelName: string;
  sn: string;
  maskKind: string;
  maskKindName: string;
  maskItem: string;
  ccdId: string;
  expiredDate: string;
  nextPaymentDate: string;
  currentPeriod: number;
  maxPeriod: number;
}

export interface MemberBuyingDto {
  accessoriesDetail: AccessoriesDetailDto[];
  address: string;
  apapModel: string;
  apapModelName: string;
  cancelDate: Date;
  ccdId: string;
  companyId: number;
  companyName: string;
  currentPeriod: number;
  email: string;
  endDate: Date;
  expiredDate: string;
  invoiceTake: string;
  invoiceTakeName: string;
  itemName: string;
  legal: string;
  maskItem: string;
  maskKind: string;
  maskKindName: string;
  maxPeriod: number;
  memberId: string;
  memberType: number;
  mobile: string;
  name: string;
  nextPaymentDate: Date;
  other: any;
  period: string;
  periodName: string;
  phone: string;
  postCode: string;
  remindData: RemindDataDto[];
  rsId: string;
  rsStatus: string;
  sn: string;
  soCode: string;
  startDate: string;
  storeId: string;
  storeName: string;
  taxId: string;
}

export interface MemberUsageRateDto {
  allDays: number;
  code: string;
  lastTracedDate: string;
  name: string;
  serialNo: string;
  shipDate: string;
  staff: string;
  storeCode: string;
  storeName: string;
  thirtyDaysUsedStatus: boolean;
  threeDaysAverageAHI: boolean;
  threeDaysAverageLeakVent: boolean;
  threeDaysUnusedStatus: boolean;
  type: string;
  usedDays: number;
  usedRate: string;
}

export interface MemberUsageStatusDto {
  airViewCode: string;
  allDays: number;
  birthday: Date;
  cai: any;
  code: string;
  createdAt: Date;
  customerTherapyDatas: CustomerTherapyDataDto[];
  deleted: boolean;
  id: number;
  isTest: boolean;
  lastTraceDate: Date;
  location: string;
  mobile: string;
  name: string;
  serialNo: string;
  setupDate: Date;
  shipDate: Date;
  staff: string;
  store: string;
  storeId: number;
  thirtyDaysUsedStatus: boolean;
  threeDaysAverageAhi: string;
  threeDaysAverageLeakVent: string;
  threeDaysUnusedStatus: boolean;
  type: number;
  updatedAt: Date;
  usedDays: number;
  usedRates: string;
}

export interface CustomerTherapyDataDto {
  ahi: string;
  ahiAbove: boolean;
  createdAt: Date;
  customerId: string;
  customer_id: string;
  date: Date;
  id: number;
  leakValve: string;
  leakValveAbove: boolean;
  leakVent: string;
  leakVentAbove: boolean;
  setupDateDays: number;
  updatedAt: Date;
  usage: string;
  usageBelow: boolean;
}

export interface RemindDataDto {
  id: number;
  itemCode: number;
  remindDate: string;
  replacementFrequency: ReplacementFrequency[];
}

interface AccessoriesDetailDto {
  DateTime: Date;
  AccessoriesName: string;
}

interface ReplacementFrequency {
  frequencyType: string;
  frequencyValue: number;
}

interface DeviceDto {
  cardBan: string;
  cardNo1: string;
  cardNo2: string;
  cardType: string;
}

export interface MemberDeviceDto extends DeviceDto {
  invoiceGovUrl: string;
  backUrl: string;
  token: string;
}

export interface MemberDeviceAuthDto extends DeviceDto {
  invoiceGovUrl: string;
  signature: string;
  token: string;
}
