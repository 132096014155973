import React from 'react';
import { Row, Col, Descriptions, Divider, List, Empty } from 'antd';
import { MemberDto } from '../../../transformers/member.dto';
import moment from 'moment';
import { ShippingOrderReceiveRecordDto } from '../../../transformers/shippingOrder.dto';

interface Props {
  memberData: MemberDto;
  dataSource: ShippingOrderReceiveRecordDto;
  currentSnData: any;
}

const ReceiveRecordForm: React.FC<Props> = (props) => {
  const { dataSource, currentSnData } = props;
  const { orderDetail, shippingOrderDetails } = dataSource;
  const nextAccessoriesDate = () => {
    if (currentSnData && currentSnData.accessoriesDetail) {
      const nextDate = currentSnData.accessoriesDetail.find((item: any) => {
        return moment(item?.dateTime) > moment();
      });
      if (nextDate) {
        return moment(nextDate.dateTime).format('YYYY-MM-DD');
      } else {
        return '-';
      }
    }
  };

  const lastAccessoriesDate = () => {
    if (currentSnData && currentSnData.accessoriesDetail) {
      const shippedResult: string[] = [];
      currentSnData.accessoriesDetail.forEach((item: any) => {
        if (item?.shippedCode !== null) {
          shippedResult.push(item?.dateTime);
        }
      });
      if (shippedResult.length > 0) {
        return moment(shippedResult.sort().pop()).format('YYYY-MM-DD');
      } else {
        return '-';
      }
    }
  };

  return (
    <Row className="member-form" justify="space-between">
      <Col xs={24} sm={24} md={10}>
        <span className="receive-yet">配件領取清單</span>
        <List
          itemLayout="horizontal"
          dataSource={orderDetail}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.name}
                description={
                  <Row justify="space-between">
                    <Col span={8}>
                      <span className="receive-total-qty">總數 {item.shippedQty + item.allowedQty}</span>
                    </Col>
                    <Col span={8}>
                      <span className="received-qty">已領取 {item.shippedQty}</span>
                    </Col>
                    <Col span={8}>
                      <span className="receive-yet-qty">未領取 {item.allowedQty}</span>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Col>
      <Divider className="vertical-divider" type="vertical" />
      <Col xs={24} sm={24} md={12}>
        <span className="received">已領取</span>
        {shippingOrderDetails && shippingOrderDetails.length > 0 ? (
          shippingOrderDetails.map((item, index) => {
            return (
              <Descriptions key={index} column={1} colon={false}>
                <Descriptions.Item label="領取日">
                  {moment(item.shippingDate).format('YYYY-MM-DD') || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="領取門市">
                  {`${item.company.name} - ${item.store.name}` || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="領取配件" className="received-details">
                  {item.details.map((ship) => {
                    return (
                      <Row key={ship.seqId} justify="space-between">
                        <Col>{ship.name}</Col>
                        <Col span={2} className="received-qty">
                          x {ship.qty}
                        </Col>
                      </Row>
                    );
                  })}
                </Descriptions.Item>
              </Descriptions>
            );
          })
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <Divider />
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="本次領取日">{lastAccessoriesDate()}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="下次領取日">{nextAccessoriesDate()}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default ReceiveRecordForm;
