import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const ContractContainer: React.FC<Props> = (props) => {
  return (
    <div className="contract-container">
      <h1>科林睡得美《訂閱條款》</h1>
      <p>
        本《科林睡得美訂閱條款》（以下簡稱“本條款”）是關於您成為科林儀器股份有限公司旗下品牌「科林睡得美」（以下簡稱“本公司”）的訂閱方案VIP會員（以下簡稱“VIP會員”）並使用本公司提供的VIP會員服務（以下簡稱“本服務”）所訂立的協議。
      </p>
      <p>
        請詳閱本條款、《科林睡得美隱私政策》及“會員中心”頁面上的具體說明（統稱為“全部協議”）。您使用本服務的行為（包括同意本協議並就本服務支付的任何款項）將視為您已閱讀並理解和同意全部協議，並接受本協議和《科林睡得美隱私政策》的約束。
      </p>
      <section>
        <h2>1.服務說明</h2>
        <p>作為訂閱會員，您將有權享受VIP會員權益，包括：</p>
        <p style={{ marginLeft: '1em' }}>
          (a)
          專業睡眠團隊會在您的訂閱期間持續追蹤您的使用狀況，協助您將正壓呼吸器配戴的更好，讓您能不間斷的使用，以達成良好治療效果。
        </p>
        <p style={{ marginLeft: '1em' }}>
          (b) 您可登入本公司官網，查看您選擇的訂閱方案、訂閱到期日、正壓呼吸器序號、截至目前為止的領取配件之日期及數量。
        </p>
      </section>
      <section>
        <h2>2.會員資格與訂閱</h2>
        <p style={{ marginLeft: '1em' }}>
          2.1
          您保證，您已根據真實、準確、合法且有效的個人資料註冊成為VIP會員。您進一步保證，您不時提供的其他個人資料也是真實、準確、合法且有效的。如果您的個人資料有任何變化，您應及時根據新的個人資料更新您的帳戶或至本公司服務據點進行變更。
        </p>
        <p style={{ marginLeft: '1em' }}>
          2.2
          您應自行負責妥善保管您的科林睡得美帳戶、帳戶資料和帳戶密碼。您應採取一切必要且有效的措施保護您的帳戶資料和密碼。在適用法律允許的最大範圍內，本公司無須承擔非因本公司原因導致的帳戶資料（包括您的帳戶密碼）洩露，和／或因您使用或保管帳戶不當導致的任何損失。
        </p>
        <p style={{ marginLeft: '1em' }}>
          2.3
          您可通過登錄本公司“VIP會員中心”查看您的VIP會員帳戶資訊，包括本服務當前提供的訂閱方案、訂閱到期日、正壓呼吸器序號、截至目前為止的領取配件之日期及數量以及交易歷史記錄等。
        </p>
      </section>
      <section>
        <h2>3.收費相關說明</h2>
        <p style={{ marginLeft: '1em' }}>
          3.1
          作為VIP會員，首期訂閱費用需於本公司服務據點以刷卡或現金方式付款；爾後其他期別之款項您可以使用登記在您名下的信用卡或銀行帳戶自動轉帳之方式支付VIP會員費（下稱“訂閱費”）。若您於訂閱期間欲取消訂閱，需親至本公司服務據點辦理，並以信用卡或現金方式支付違約金。
        </p>
        <p style={{ marginLeft: '1em' }}>3.2 本服務均按月度方式支付該月訂閱費，每月扣款日依照您的合約起始日而定。</p>
        <p style={{ marginLeft: '1em' }}>
          3.3
          如果您希望更新您的支付信用卡號及信用卡到期日，您可以登錄您的本公司帳戶進行相關操作。更新支付帳戶或收費週期後，您授權本公司繼續根據更新後的支付資訊向您收取費用。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.4
          欲以銀行帳戶自動轉帳之方式支付訂閱費，首期訂閱費需於門市支付現金，填寫ACH「自動轉帳授權書」以便授權轉帳，並提供本公司您的銀行存簿影本。取消訂閱時，亦需填寫自動轉帳授權書以便終止轉帳授權，惟需於下次扣款日之五日前提出取消訂閱之申請。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.5
          您知悉並同意，您確保您的支付帳戶資訊是最新的。如果本公司在訂閱費到期日通過您的支付帳戶扣款失敗，無論是因額度不足、換發新卡或其他原因，本公司有權暫停或終止您對本服務的使用。但由於取消本自動續費服務需要VIP會員主動進行第3.6條規定的操作，如果您未進行此等操作，則將視為您同意本公司可依據支付服務提供者的扣款規則在收費週期後進行不時的扣款嘗試（即使您的額度不足）。一旦您更新了您的支付帳戶資訊、訂閱費足額支付，本公司將恢復您對本自動續費服務的使用權限。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.6
          您可以至本公司服務據點隨時以任何理由取消自動續訂。完成上述取消操作後，您的自動續訂將在當前收費週期結束時自動終止。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.7 您理解並同意，帳單錯誤或扣款錯誤本公司無須負責損害賠償，本公司僅在收到此類錯誤通知時，負責更正此類錯誤。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.8 本公司有權自行決定是否調整訂閱費、支付方式和收費週期，本公司將依據下述第6條規定的變更程序公佈此類調整。
        </p>
        <p style={{ marginLeft: '1em' }}>
          {' '}
          3.9 發票均採電子發票方式存入會員載具，會員載具發票若中獎時，將由專人寄送中獎發票紙本給您。
        </p>
        <p style={{ marginLeft: '1em' }}>
          3.10
          若提前取消訂閱，違約金計算方式為一個月訂閱費用及一副面罩優惠價格之加總金額，實際收取金額以訂閱方案(申請/異動/取消)
          申請書上為主。
        </p>
      </section>
      <section>
        <h2>4.服務變更說明</h2>

        <p style={{ marginLeft: '1em' }}>4.1 除信用卡號變更外，其他變更均需至本公司服務據點親自辦理。</p>
        <p style={{ marginLeft: '1em' }}>4.2 每次變更均於次月合約生效日時生效。</p>
      </section>
      <section>
        <h2>5.服務使用說明</h2>
        <p style={{ marginLeft: '1em' }}>
          5.1
          本服務和主機歸本公司所有，並受商標、專利或其他智慧財產權的保護。如果您擁有有效的VIP會員資格，本公司授予您一項個人的、有限的、非獨家的、不可轉讓的、可撤銷的使用本服務的權利和許可。在任何時候，您都不得將本服務或VIP會員用於商業目的或其他可能侵犯本公司和／或其關係企業和／或協力廠商合法權益的目的。更明確地說，本服務僅供您個人使用。
        </p>
        <p style={{ marginLeft: '1em' }}>
          5.2
          您知悉並同意，如果本公司提供多種訂閱方案，您的VIP會員權益將會因您所購買的訂閱方案不同而有所差別。“VIP會員中心”會對您的VIP會員權益的內容進行進一步說明。
        </p>
        <p style={{ marginLeft: '1em' }}>
          5.3
          您的VIP會員僅限於自行使用，且在本服務期內不得在本公司帳戶之間轉移。您不得贈與、借用、租用、轉讓或售賣您的本公司帳戶和相關商品，包括但不限於VIP會員帳戶。您不得以未獲本公司授權的非法銷售、轉讓本公司VIP會員的權益。
        </p>
      </section>
      <section>
        <h2>6.本協議的修改</h2>
        <p style={{ marginLeft: '1em' }}>
          6.1
          本公司有權自行決定不時修改本協議（包括VIP會員權益），一旦本協議條款發生變更，本公司會將此類修改在“VIP會員中心”上公示或第9條中規定的其他方式通知您。修改內容將在更新後十五日後生效。在修改內容生效前，您可以選擇不接受修改內容並取消本服務。如果本協議的修改會導致您的義務增加或本公司的義務減少，在適用法律允許的最大範圍內，作為您的唯一救濟措施，並且只在法律要求的情況下，您可以在修改內容生效後的十五日內向本公司提供拒絕通知，拒絕修改並撤銷，或取消本服務，而無須承擔費用、罰款或取消費。在適用法律允許的最大範圍內，您在上述規定的變更通知期後繼續訪問或使用本服務將視為您完全接受並同意變更後的全部協議，並接受在“VIP會員中心”公示的或通知您的變更後的本服務或訂閱的約束。本公司建議您經常查看本協議條款，並且定期查看“VIP會員中心”公佈的資訊，以保護您的權益。
        </p>
      </section>
      <section>
        <h2>7.服務的中斷和終止</h2>
        <p style={{ marginLeft: '1em' }}>
          7.1 如果您違反或未遵守本協議任何條款，本公司有權自行決定取消您的VIP會員資格，並且您繳交之訂閱費將不予退還。
        </p>
        <p style={{ marginLeft: '1em' }}>
          7.2
          在不限制第8.1條規定的提前終止權利的情況下，在適用法律允許的範圍內，本公司會在提前書面通知您後，才取消您對本服務的訂閱。
        </p>
        <p style={{ marginLeft: '1em' }}>
          7.3
          本公司未行使或遲延行使本協議項下的任何權利不構成對該權利的放棄，單一或部分行使其在本協議項下的任何權利並不排斥其任何其它權利的行使。
        </p>
      </section>
      <section>
        <h2>8.隱私政策</h2>
        <p style={{ marginLeft: '1em' }}>
          有關您的個人資料的處理和保護、您對您的個人資訊的控制以及其他相關內容，請參見《本公司隱私政策》。
        </p>
      </section>
      <section>
        <h2>9.通知</h2>
        <p style={{ marginLeft: '1em' }}>
          為方便您及時獲知本公司的相關資訊、與本服務和本協議相關的資訊，您同意本公司有權通過以下任一方式通知您：網頁公示、頁面提示、彈窗、消息通知和您的聯繫方式（例如電子信箱、電話、簡訊、聯繫地址）。任何此類通知自本公司發送之日被視為已向您送達。如同時使用多種通知方式的，則送達時間以上述方式中最早發送之時為準。
        </p>
      </section>
      <section>
        <h2>10.法律的適用和管轄</h2>
        <p style={{ marginLeft: '1em' }}>
          10.1
          本協議以中華民國之相關法令為準據法；倘因本協議所生事項涉訟時，雙方同意以臺灣臺北地方法院為第一審管轄法院。
        </p>
      </section>
      <section>
        <h2>11.其他</h2>
        <p style={{ marginLeft: '1em' }}>
          11.1 本服務僅限由科林國際助聽器、濰樂聽力中心和聽寶助聽中心提供，並無法與該通路其他優惠活動或集點活動併用。
        </p>
        <p style={{ marginLeft: '1em' }}>
          11.2
          如果您對本協議或本服務的使用有任何問題或意見（包括問題諮詢或投訴），請透過免費服務專線0800-551188或電子郵箱service.resmed@clinico.com.tw聯繫我們。本公司將在收到您的問題或意見後儘快向您回覆。
        </p>
        <p style={{ marginLeft: '1em' }}>11.3 本協議的標題僅為方便閱讀而設，並不影響本協議中任何規定的含義或解釋。</p>
        <p style={{ marginLeft: '1em' }}>11.4 包括”一詞系指“包括但不限制前述內容”。</p>
        <p style={{ marginLeft: '1em' }}>
          11.5
          本公司未能執行全部協議項下的某項權利或規定，不應構成或視為對該項權利或規定的放棄。如果全部協議的任何條款（或其部分）被有管轄權的法院認定為無效或不可執行，該認定並不影響全部協議項下的其他條款（或其部分）的效力。對本公司違反全部協議的任何條款的豁免，並不構成對本公司事先、事中或事後違反相同條款或任何其他條款的豁免。如果全部協議中的任何條款是非法、無效或因任何原因是不可執行的，則該條款視為與全部協議可分割的且不會影響其他條款的有效性和可執行性。
        </p>
        <p style={{ marginLeft: '1em' }}>11.6 如果本協議有多種語言版本，當不同版本間存在不一致時，以中文版本為準。</p>
      </section>
      <p className="text-right">2022/07/11</p>
      <p className="text-right">科林儀器股份有限公司</p>
    </div>
  );
};

export default withBaseContainer(withRouter(ContractContainer));
