import { BaseAPI, APIResponse } from './base.api';
import { AuthDto } from '../transformers/auth.dto';

export class AuthAPI extends BaseAPI {
  static async login(data: { email: string; password: string }): Promise<APIResponse<AuthDto>> {
    let result = await BaseAPI.call<AuthDto>({
      path: `/subscribed/auth/login`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async logout(data: { email: string; token: string }): Promise<APIResponse<undefined>> {
    let result = await BaseAPI.call<undefined>({
      path: `/subscribed/auth/logout`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async resetPassword(data: { email: string }): Promise<APIResponse<AuthDto>> {
    let result = await BaseAPI.call<AuthDto>({
      path: `/subscribed/auth/resetPassword`,
      method: 'POST',
      data,
    });

    return result;
  }
}
