import Profile from './profile.form';
import SubscribeData from './subscribeData.form';
import BuyoutData from './buyoutData.form';
import ChangePassword from './changePassword.form';
import ReceiveRecord from './receiveRecord.form';

export default {
  Profile,
  SubscribeData,
  BuyoutData,
  ChangePassword,
  ReceiveRecord,
};
