import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import RankTable from '../components/rank/rankTable';
import { AchievementService } from '../services/achievement.service';
import { MemberAchievement } from '../transformers/achievemen.dto';
import { withBaseContainer, WithBaseContainerProps } from './base.container';

interface Props extends WithBaseContainerProps {}
const RankContainer: React.FC<Props> = (props) => {
  const { showErrorModal } = props;
  const [ranks, setRanks] = useState<MemberAchievement[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRank = async () => {
    setLoading(true);
    try {
      const rankData = await AchievementService.rank({ offset: 0, limit: 50 });
      setRanks(rankData);
    } catch (error: any) {
      showErrorModal(error, error.type);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchRank();
  }, []);

  return (
    <Row style={{ padding: 16 }} justify="center" gutter={[16, 16]}>
      <Col xs={24} xl={16} xxl={12}>
        <RankTable ranks={ranks} loading={loading} />
      </Col>
    </Row>
  );
};
export default withBaseContainer(RankContainer);
