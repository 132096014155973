import React from 'react';
import { Row, Col, Button, Form, Input } from 'antd';

export interface FormValues {
  email: string;
  password: string;
  newPassword: string;
}

interface Props {
  processing?: boolean;
  email?: string;
  password?: string;
  onSubmit?: (values: FormValues) => void;
  onCancel?: () => void;
}

const changePasswordForm: React.FC<Props> = (props) => {
  const { processing, email, password, onSubmit, onCancel } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
    colon: false,
  };

  const handleSubmit = (values: { [name: string]: any }): void => {
    onSubmit && onSubmit({ email: values.email, password: values.password, newPassword: values.newPassword });
  };

  return (
    <Form className="form login-form" initialValues={{ email, password }} {...formItemLayout} onFinish={handleSubmit}>
      <Form.Item label="帳號" name="email" rules={[{ required: true, message: '請輸入您的帳號' }]}>
        <Input placeholder="請輸入您的帳號" disabled={processing} />
      </Form.Item>
      <Form.Item label="原密碼" name="password" rules={[{ required: true, message: '請輸入密碼' }]}>
        <Input type="password" placeholder="請輸入密碼" disabled={processing} />
      </Form.Item>
      <Form.Item label="新密碼" name="newPassword" rules={[{ required: true, message: '請輸入密碼' }]}>
        <Input type="password" placeholder="請輸入密碼" disabled={processing} />
      </Form.Item>
      {/* TODO 密碼強度判斷功能 */}
      <Form.Item
        label="確認密碼"
        name="confirmPassword"
        dependencies={['newPassword']}
        hasFeedback
        rules={[
          { required: true, message: '請輸入密碼' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject('輸入密碼不一致');
            },
          }),
        ]}
      >
        <Input type="password" placeholder="請輸入密碼" disabled={processing} />
      </Form.Item>

      <Row justify="center">
        <Col span={7}>
          <Button className="btn-master" loading={processing} onClick={onCancel}>
            取消
          </Button>
        </Col>
        <Col span={7}>
          <Button htmlType="submit" className="btn-second" loading={processing}>
            更改
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default changePasswordForm;
