import { Col, Row, Spin } from 'antd';
import React from 'react';
import { MemberAchievement } from '../../transformers/achievemen.dto';
import RankItem from './rankItem';
import './rankTable.scss';

interface Props {
  ranks?: MemberAchievement[];
  loading?: boolean;
}
const RankTable: React.FC<Props> = (props) => {
  const { ranks, loading = false } = props;
  return (
    <div className="rank-card">
      <div className="card-title">
        <div className="text">★ XP王者排行榜 ★</div>
      </div>
      <div className="card-content">
        <Spin spinning={loading}>
          <Row gutter={[8, 8]}>
            {ranks?.map((rank) => (
              <Col key={rank.member?.memberCode} span={24}>
                <RankItem memberAchievement={rank} />
              </Col>
            ))}
          </Row>
        </Spin>
      </div>
    </div>
  );
};
export default RankTable;
