import { BaseAPI, APIResponse } from './base.api';
import {
  MemberDto,
  MemberDeviceDto,
  MemberDeviceAuthDto,
  MemberBuyingDto,
  MemberUsageStatusDto,
  MemberUsageRateDto,
} from '../transformers/member.dto';
import {
  ShippingOrderListDto,
  ShippingOrderDetailDto,
  ShippingOrderReceiveRecordDto,
} from '../transformers/shippingOrder.dto';
import { MemberAchievement } from '../transformers/achievemen.dto';
import qs from 'qs';

export class MemberAPI extends BaseAPI {
  // 顧客資料
  static async findOne(): Promise<APIResponse<MemberDto[]>> {
    let result = await BaseAPI.call<MemberDto[]>({
      // path: `/subscribed/application/latest`,
      path: `/subscribed/application/memberInfo`,
      method: 'GET',
    });
    return result;
  }
  // 顧客購買清單資料
  static async findBuyingList(): Promise<APIResponse<MemberBuyingDto[]>> {
    let result = await BaseAPI.call<MemberBuyingDto[]>({
      path: `/subscribed/application/memberInfo`,
      method: 'GET',
    });

    return result;
  }
  // 顧客使用狀況 by 月份
  static async memberUsageStatus(params: {
    customerCode: string;
    sn: string;
    searchDate: string;
  }): Promise<APIResponse<MemberUsageStatusDto[]>> {
    let query = qs.stringify({
      customerCode: params.customerCode,
      sn: params.sn,
      searchDate: params.searchDate,
    });
    let result = await BaseAPI.call<MemberUsageStatusDto[]>({
      path: `/subscribed/application/getMonthlyTherapyData?` + query,
      method: 'GET',
    });

    return result;
  }

  // 顧客使用狀況 by 購買日
  static async memberUsageRate(params: any): Promise<APIResponse<MemberUsageRateDto>> {
    let query = qs.stringify({
      customerCode: params.customerCode,
      sn: params.sn,
    });
    let result = await BaseAPI.call<MemberUsageRateDto>({
      path: `/subscribed/application/getCustomerUsedRate?` + query,
      method: 'GET',
    });

    return result;
  }

  // 更新提醒時間
  static async updateRemindDate(data: { id: number }): Promise<APIResponse<any>> {
    let result = await BaseAPI.call<any>({
      path: `/subscribed/application/updateRemindDate`,
      method: 'POST',
      data,
    });

    return result;
  }

  // 舊顧客（買斷）補註冊
  static async registerMember(data: { email: string; token: string }): Promise<APIResponse<any>> {
    let result = await BaseAPI.call<any>({
      path: `/subscribed/member/registerMember`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async changePassword(data: {
    email: string;
    password: string;
    newPassword: string;
  }): Promise<APIResponse<MemberDto>> {
    let result = await BaseAPI.call<MemberDto>({
      path: `/subscribed/member/changePassword`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async forgetAndChangePassword(data: { token: string; password: string }): Promise<APIResponse<MemberDto>> {
    let result = await BaseAPI.call<MemberDto>({
      path: `/subscribed/member/resetPassword`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async updateCreditCard(data: {
    rsId: string;
    cardNumber: string;
    month: number;
    year: number;
  }): Promise<APIResponse<undefined>> {
    let result = await BaseAPI.call<undefined>({
      path: `/subscribed/application/updateCreditCard`,
      method: 'POST',
      data,
    });

    return result;
  }

  static async findAllOfShippingOrder(): Promise<APIResponse<ShippingOrderListDto[]>> {
    let result = await BaseAPI.call<ShippingOrderListDto[]>({
      path: `/subscribed/shippingOrder`,
      method: 'GET',
    });

    return result;
  }

  static async shippingOrderDetail(orderCode: any): Promise<APIResponse<ShippingOrderDetailDto>> {
    let result = await BaseAPI.call<ShippingOrderDetailDto>({
      path: `/subscribed/shippingOrder/${orderCode}`,
      method: 'GET',
    });

    return result;
  }

  static async shippingOrderReceiveRecord(orderCode: any): Promise<APIResponse<ShippingOrderReceiveRecordDto>> {
    let result = await BaseAPI.call<ShippingOrderReceiveRecordDto>({
      path: `/subscribed/shippingOrder/${orderCode}/shippingRecords`,
      method: 'GET',
    });

    return result;
  }

  static async memberDevice(rsId: string): Promise<APIResponse<MemberDeviceDto>> {
    let result = await BaseAPI.call<MemberDeviceDto>({
      path: `/subscribed/member/deviceParams`,
      method: 'GET',
      params: { rsId: rsId },
    });

    return result;
  }

  static async memberDeviceAuth(rsId: string, token: string): Promise<APIResponse<MemberDeviceAuthDto>> {
    let result = await BaseAPI.call<MemberDeviceAuthDto>({
      path: `/subscribed/member/largeToSmallDeviceParams`,
      method: 'GET',
      params: { rsId: rsId, token: token },
    });

    return result;
  }
  static async memberAchievement(): Promise<APIResponse<MemberAchievement>> {
    let result = await BaseAPI.call<MemberAchievement>({
      path: `/subscribed/application/memberAchievement`,
      method: 'GET',
    });
    return result;
    // return MockMemberAchievement;
  }
}

const MockMemberAchievement: APIResponse<MemberAchievement> = {
  success: true,
  result: {
    data: {
      score: 10000,
      level: 2,
      current: 4000,
      nextStage: 6000,
      achievements: [
        {
          mission: '購機/訂閱60期',
          name: '實踐家',
          value: 2,
          prize: 'Silver',
          score: 30000,
          prizeDetail: {
            Bronze: {
              desc: '1台',
              score: 5000,
            },
            Silver: {
              desc: '2台',
              score: 25000,
            },
            Gold: {
              desc: '3台',
              score: 50000,
            },
            Platinum: {
              desc: '4台',
              score: 100000,
            },
          },
        },
        {
          mission: '介紹朋友HST',
          name: '衛教師',
          value: 1,
          prize: 'Bronze',
          score: 1000,
          prizeDetail: {
            Bronze: {
              desc: '1人',
              score: 1000,
            },
            Silver: {
              desc: '2人',
              score: 5000,
            },
            Gold: {
              desc: '3人',
              score: 10000,
            },
            Platinum: {
              desc: '4人',
              score: 20000,
            },
          },
        },
        {
          mission: '介紹朋友購機/訂閱',
          name: '召喚師',
          value: 0,
          prize: null,
          score: 0,
          prizeDetail: {
            Bronze: {
              desc: '1人',
              score: 10000,
            },
            Silver: {
              desc: '2人',
              score: 50000,
            },
            Gold: {
              desc: '3人',
              score: 100000,
            },
            Platinum: {
              desc: '4人',
              score: 200000,
            },
          },
        },
        {
          mission: '提供分享圖文',
          name: '微網紅',
          value: 15,
          prize: 'Bronze',
          score: 1500,
          prizeDetail: {
            Bronze: {
              desc: '上架',
              score: 1500,
            },
            Silver: {
              desc: 'FB讚數30',
              score: 7500,
            },
            Gold: {
              desc: 'FB讚數60',
              score: 15000,
            },
            Platinum: {
              desc: 'FB讚數120',
              score: 30000,
            },
          },
        },
        {
          mission: '提供分享影片',
          name: '影舞者',
          value: 0,
          prize: null,
          score: 0,
          prizeDetail: {
            Bronze: {
              desc: '上架',
              score: 5000,
            },
            Silver: {
              desc: 'FB讚數30',
              score: 25000,
            },
            Gold: {
              desc: 'FB讚數60',
              score: 50000,
            },
            Platinum: {
              desc: 'FB讚數120',
              score: 100000,
            },
          },
        },
        {
          mission: '出席分享會/記者會',
          name: '發言人',
          value: 0,
          prize: null,
          score: 0,
          prizeDetail: {
            Bronze: {
              desc: '1次',
              score: 5000,
            },
            Silver: {
              desc: '2次',
              score: 25000,
            },
            Gold: {
              desc: '3次',
              score: 50000,
            },
            Platinum: {
              desc: '4次',
              score: 100000,
            },
          },
        },
        {
          mission: '連續每天登入',
          name: '鐵粉',
          value: 0,
          prize: null,
          score: 0,
          prizeDetail: {
            Bronze: {
              desc: '30天',
              score: 2500,
            },
            Silver: {
              desc: '90天',
              score: 5000,
            },
            Gold: {
              desc: '120天',
              score: 10000,
            },
            Platinum: {
              desc: '360天',
              score: 500,
            },
          },
        },
        {
          mission: '每日持續使用',
          name: '長跑者',
          value: 0,
          prize: null,
          score: 0,
          prizeDetail: {
            Bronze: {
              desc: '30天',
              score: 25000,
            },
            Silver: {
              desc: '90天',
              score: 50000,
            },
            Gold: {
              desc: '120天',
              score: 100000,
            },
            Platinum: {
              desc: '360天',
              score: 5000,
            },
          },
        },
      ],
    },
  },
};
