import { AchievementAPI, AchievementRankParams } from '../api/achievement.api';

export class AchievementService {
  static async rank(params: AchievementRankParams) {
    let res = await AchievementAPI.rank(params);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data;
  }
}
