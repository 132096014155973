import React from 'react';
import { Row, Col, Button, Form, Input } from 'antd';

export interface RegisterFormValues {
  email: string;
  mobile?: string;
}

interface Props {
  processing?: boolean;
  email?: string;
  onSubmit?: (values: RegisterFormValues) => void;
  withMobile?: boolean;
}

const RegisterForm: React.FC<Props> = (props) => {
  const { processing, email, onSubmit, withMobile } = props;

  const handleSubmit = (values: RegisterFormValues): void => {
    onSubmit && onSubmit(values);
  };

  return (
    <Form className="form login-form" initialValues={{ email }} onFinish={handleSubmit}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: '請輸入您的E-mail' },
          { type: 'email', message: 'E-mail格式錯誤' },
        ]}
      >
        <Input placeholder="請輸入您的E-mail" disabled={processing} />
      </Form.Item>
      {withMobile && (
        <Form.Item
          name="mobile"
          rules={[
            { required: true, message: '請輸入您的行動電話' },
            { pattern: /09\d{8}/, message: '行動電話格式錯誤' },
          ]}
        >
          <Input placeholder="請輸入您的行動電話" disabled={processing} />
        </Form.Item>
      )}
      <Row justify="center">
        <Col span={7}>
          <Button htmlType="submit" className="btn-master" loading={processing}>
            註冊
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RegisterForm;
