import React from 'react';

import { Card, Col, Row } from 'antd';
import { Achievement, Prize } from '../../transformers/achievemen.dto';

import './achievement.scss';

interface Props {
  size?: 'small' | 'middle';
  achievement: Omit<Achievement, 'prizeDetail'>;
}
const AchievementCard: React.FC<Props> = ({ size = 'middle', ...props }) => {
  const { achievement } = props;
  return (
    <Card className={`achievement-card ${size}`} bodyStyle={{ height: size === 'middle' ? 80 : 64 }} size="small">
      {!achievement.prize && (
        <Row align="middle" style={{ height: '100%' }}>
          <Col xs={24} className="prize-name">
            {achievement.name}
          </Col>
        </Row>
      )}
      {achievement.prize && (
        <div className="prize-img">
          <img
            src={`/images/prize/${achievement.name}/${achievement.prize.toLocaleLowerCase()}.png`}
            height="100%"
            width="100%"
          />
        </div>
      )}
    </Card>
  );
};
export default AchievementCard;
