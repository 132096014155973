import React from 'react';
import { Card, Col, Divider, Progress, Row, Popover } from 'antd';

import AchievementDescCard from './achievementDecs.card';
import XPKingCard from './XPKing.card';

import { MemberAchievement } from '../../transformers/achievemen.dto';

import './achievement.scss';
import { Link } from 'react-router-dom';
import AchievementCard from './achievement.card';

interface Props {
  memberAchievement?: MemberAchievement;
}
const AchievementBoard: React.FC<Props> = (props) => {
  const { memberAchievement } = props;
  return (
    <Card className="achievement-board">
      <Row gutter={[12, 12]}>
        <Col className="achievement--header" xs={24}>
          <div className="chip">
            <span>成就解鎖 勳章GET</span>
          </div>
          <div className="main-title">會員積分挑戰賽</div>
          <div className="sub-title">王者榮耀，誰與爭鋒</div>
        </Col>
        <Col style={{ textAlign: 'center', marginBottom: 12 }} xs={24}>
          <Progress
            width={180}
            strokeColor="#FBC600"
            strokeWidth={8}
            type="circle"
            percent={memberAchievement ? (memberAchievement.current! / memberAchievement.nextStage!) * 100 : 0}
            format={() => (
              <div className="level-card">
                <div className="exp-percent">
                  <div>{memberAchievement?.current ?? '-'}</div>
                  <Divider style={{ margin: '4px 0' }} />
                  <div className="exp-total">{memberAchievement?.nextStage ?? '-'}</div>
                </div>
                <div>
                  <div className="level">{memberAchievement?.level ?? '-'}</div>
                  <div className="level-text">等級</div>
                </div>
              </div>
            )}
          />
        </Col>
        <Col xs={24}>
          <Link to="/rank" target={'_blank'}>
            <XPKingCard memberAchievement={memberAchievement} />
          </Link>
        </Col>
        {memberAchievement?.achievements.map((achievement) => (
          <Col xs={8} key={achievement.name}>
            <Popover content={<AchievementDescCard achievement={achievement} />}>
              <span>
                <AchievementCard achievement={achievement} />
              </span>
            </Popover>
          </Col>
        ))}
      </Row>
    </Card>
  );
};
export default AchievementBoard;
