import Detail from './detail.container';
import CCIDModify from './ccidModify.container';
import SubscribeModify from './subscribeModify.container';
import ChangePassword from './changePassword.container';

export default {
  Detail,
  CCIDModify,
  SubscribeModify,
  ChangePassword,
};
