import React from 'react';
import Media from 'react-media';
import { Row, Col, Descriptions, Divider, Button, Steps } from 'antd';
import { MemberDto } from '../../../transformers/member.dto';

interface Props {
  memberData: MemberDto;
  onChangeSubscribe?: (value?: any) => void;
  onMemberDevice: (value: string) => void;
}

const ProfileForm: React.FC<Props> = (props) => {
  const { memberData, onMemberDevice } = props;

  const invoiceTake = (value: any) => {
    switch (value) {
      case '1':
        return '電子發票';
      case '2':
        return '會員載具';
      default:
        return '-';
    }
  };

  const rsStatus = (value: any) => {
    switch (value) {
      case '1':
        return '新申請';
      case '2':
        return '訂閱期滿';
      case '9':
        return '取消訂閱';
      default:
        return '-';
    }
  };

  return (
    <Row className="member-form" justify="space-between">
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="姓名">{memberData?.name || '-'}</Descriptions.Item>
          <Descriptions.Item label="會員編號">
            {memberData?.memberId || '-'}
            <Divider type="vertical" />
            {memberData ? invoiceTake(memberData.invoiceTake) : '-'}
            {memberData.rsId && (
              <Button className="btn-account" size="small" onClick={() => onMemberDevice(memberData.rsId)}>
                歸戶
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="E mail">{memberData?.email || '-'}</Descriptions.Item>
          <Descriptions.Item label="密碼">
            <Row>
              <Col span={24}>********</Col>
              <Col>
                <Button href={`/member/${memberData?.memberId}/changePassword`} ghost type="primary" size="small">
                  設定新的密碼
                </Button>
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="手機號碼">{memberData?.mobile || '-'}</Descriptions.Item>
          <Descriptions.Item label="市話號碼">{memberData?.phone || '-'}</Descriptions.Item>
          <Descriptions.Item label="戶籍地址">{memberData?.address || '-'}</Descriptions.Item>
          <Descriptions.Item label="統一編號">{memberData?.taxId || '-'}</Descriptions.Item>
        </Descriptions>
      </Col>
      <Divider className="vertical-divider" type="vertical" />
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="訂閱單號">{memberData?.rsId || '-'}</Descriptions.Item>
          <Descriptions.Item label="訂閱期數">
            <span className="subscribe-info"> {memberData?.maxPeriod || '-'} </span> 期
          </Descriptions.Item>
          <Descriptions.Item label="已使用期數">
            <Row>
              <Col>
                <span className="subscribe-info">
                  {' '}
                  {memberData?.currentPeriod || '-'}/{memberData?.maxPeriod || '-'}{' '}
                </span>{' '}
                期
              </Col>
              {/* <Col><Button type="primary" size="small" ghost onClick={() => onChangeSubscribe('24')}>24變更</Button></Col> */}
              {/* <Col><Button type="primary" size="small" ghost onClick={() => onChangeSubscribe('60')}>60變更</Button></Col> */}
            </Row>
          </Descriptions.Item>
        </Descriptions>
        <Divider dashed />
        <Descriptions className="subscribe-info" column={1} colon={false}>
          <Descriptions.Item label="申請狀態">
            <span className="subscribe-info">{rsStatus(memberData?.rsStatus)}</span>
            {/* <Media query={{ maxWidth: 576 }}>
                            {matches =>
                                matches ? (
                                    <Steps 
                                        className="apply-process" 
                                        progressDot 
                                        size="small" 
                                        direction="vertical"
                                        current={memberData? Number(memberData.rsStatus) : 0}>
                                        <Steps.Step title="訂購完成" />
                                        <Steps.Step title="已到店" />
                                        <Steps.Step title="已領取" />
                                    </Steps>
                                ) : (
                                    <Steps 
                                        className="apply-process" 
                                        progressDot 
                                        size="small" 
                                        current={memberData? Number(memberData.rsStatus) : 0}>
                                        <Steps.Step title="訂購完成" />
                                        <Steps.Step title="已到店" />
                                        <Steps.Step title="已領取" />
                                    </Steps>
                                )
                            }
                        </Media> */}
          </Descriptions.Item>
        </Descriptions>
        <Divider dashed />
      </Col>
    </Row>
  );
};

export default ProfileForm;
