import React, { useState } from 'react';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from '../base.container';
import Components from '../../components';
import { FormValues } from '../../components/form/member/changePassword.form';
import { MemberService } from '../../services/member.service';
import { StorageService } from '../../services/storage.service';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const ChangePasswordContainer: React.FC<Props> = (props) => {
  const [changePasswordSuccess, setChangegPasswordSuccess] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    try {
      await MemberService.changePassword(values);
      setChangegPasswordSuccess(true);
      // props.history.push('/login');
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  const handleCancle = () => {
    try {
      props.history.push(`/member/${StorageService.memberId}`);
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  return (
    <div className="login-container">
      {changePasswordSuccess ? (
        <Result
          status="success"
          title="密碼變更成功"
          extra={[
            <Button type="primary" key="console" href="/login">
              回登入頁
            </Button>,
          ]}
        />
      ) : (
        <Components.Tabs.MainTabs
          tabContent={[
            {
              key: '1',
              title: '修改密碼',
              content: <Components.Form.Member.ChangePassword onSubmit={handleSubmit} onCancel={handleCancle} />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default withBaseContainer(withRouter(ChangePasswordContainer));
