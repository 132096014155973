import React from 'react';
import { Card, Descriptions } from 'antd';

import { DeviceSNMemberDto } from '../../transformers/deviceSN.dto';
interface Props {
  deviceSNMember?: DeviceSNMemberDto;
}
const CheckMemberCard: React.FC<Props> = (props) => {
  const { deviceSNMember } = props;
  return (
    <Card>
      <Descriptions column={1} colon={false}>
        <Descriptions.Item label="會員編號">{deviceSNMember?.customerId || '-'}</Descriptions.Item>
        <Descriptions.Item label="會員名稱">{deviceSNMember?.name || '-'}</Descriptions.Item>
        <Descriptions.Item label="行動電話">{deviceSNMember?.mobile || '-'}</Descriptions.Item>
        <Descriptions.Item label="產品SN">{deviceSNMember?.sn || '-'}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
export default CheckMemberCard;
