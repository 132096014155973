import { MemberAPI } from '../api/member.api';

export class MemberService {
  static async findOne() {
    let res = await MemberAPI.findOne();

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data?.[0] || undefined;
  }

  static async findBuyingList() {
    let res = await MemberAPI.findBuyingList();

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || [];
  }

  static async memberUsageStatus(params: { customerCode: string; sn: string; searchDate: string }) {
    let res = await MemberAPI.memberUsageStatus(params);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async memberAchievement() {
    let res = await MemberAPI.memberAchievement();

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data;
  }

  static async memberUsageRate(params: any) {
    let res = await MemberAPI.memberUsageRate(params);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async updateRemindDate(data: { id: number }) {
    let res = await MemberAPI.updateRemindDate(data);
    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async registerMember(data: { email: string; token: string }) {
    let res = await MemberAPI.registerMember(data);
    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async changePassword(data: { email: string; password: string; newPassword: string }) {
    let res = await MemberAPI.changePassword(data);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async forgetAndChangePassword(data: { token: string; password: string }) {
    let res = await MemberAPI.forgetAndChangePassword(data);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async updateCreditCard(data: { rsId: string; cardNumber: string; month: number; year: number }) {
    let res = await MemberAPI.updateCreditCard(data);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async findAllOfShippingOrder() {
    let res = await MemberAPI.findAllOfShippingOrder();

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || [];
  }

  static async shippingOrderDetail(orderCode: any) {
    let res = await MemberAPI.shippingOrderDetail(orderCode);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async shippingOrderReceiveRecord(orderCode: any) {
    let res = await MemberAPI.shippingOrderReceiveRecord(orderCode);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async memberDevice(rsId: string) {
    let res = await MemberAPI.memberDevice(rsId);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }

  static async memberDeviceAuth(rsId: string, token: string) {
    let res = await MemberAPI.memberDeviceAuth(rsId, token);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }
}
