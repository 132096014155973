import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Affix } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import { StorageService } from '../services/storage.service';
import Menu from './menu';
const { Header } = Layout;

interface Props {
  onShowDrawer: () => void;
  onLogout: () => void;
}

const HeaderComponent: React.FC<Props> = (props) => {
  const { onLogout, onShowDrawer } = props;
  const [isContract, setIsContract] = useState<boolean>(false);
  const [top, setTop] = useState(0);

  useEffect(() => {
    const isContract = window.location.href.indexOf('contract') !== -1;
    if (isContract) {
      setIsContract(true);
    }
  }, []);

  return (
    <Affix offsetTop={top}>
      <Header className="header">
        <Row justify="space-between" align="middle">
          <Col>
            <img className="logo" src="/images/logo.png" />
          </Col>
          <Col>
            <Menu className="nav" mode="horizontal" />
          </Col>
          <Col>
            <Row className="light-nav" gutter={16}>
              <Col>
                <a href="https://booking.resmed.ear.com.tw/booking" target="_blank" rel="noopener noreferrer">
                  <img className="serviceIcon" src="/images/serviceIcon1.svg" /> 線上預約
                </a>
              </Col>
              <Col>
                <a href="https://booking.resmed.ear.com.tw/stores" target="_blank" rel="noopener noreferrer">
                  <img className="serviceIcon" src="/images/serviceIcon1.svg" width="28" /> 服務據點
                </a>
              </Col>
              <Col>
                {StorageService.memberId && !isContract && (
                  <Button className="btn-logout" onClick={onLogout}>
                    登出
                  </Button>
                )}
              </Col>
            </Row>
          </Col>

          <Col className="sm-menu">
            <Button type="link" onClick={onShowDrawer}>
              <MenuFoldOutlined />
            </Button>
          </Col>
        </Row>
      </Header>
    </Affix>
  );
};

export default HeaderComponent;
