import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Result } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import Components from '../components';
import { LoginFormValues } from '../components/form/login.form';
import { ForgetFormValues } from '../components/form/forgetPassword.form';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { useGA4React } from 'ga-4-react';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const authService = new AuthService();

const LoginContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState('');
  const ga = useGA4React();

  const handleLoginSubmit = async (values: LoginFormValues): Promise<void> => {
    try {
      await authService.login(values);
      if (ga) {
        ga.gtag('config', process.env.REACT_APP_GA_CODE, {
          memberCode: StorageService.memberId,
        });
      }
      props.history.push(`/member/${StorageService.memberId}`);
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  const handleForgetPasswordSubmit = async (values: ForgetFormValues): Promise<void> => {
    try {
      await authService.resetPassword(values);
      setResetPassword(values.email);
      setShowResult(true);
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>科林睡得美會員登入區 | 會員積分挑戰賽熱烈進行中</title>
        <meta
          name="description"
          content="科林睡得美會員登入區 | 達成各項任務的里程碑時，即能贏得各式徽章及累積XP積分，並獲得特定贈品。"
        />
      </Helmet>
      <div className="banner">
        <h1>會員登入</h1>
      </div>
      <div className="login-container">
        {showResult ? (
          <Result status="success" title={`密碼重設連結已發送到 ${resetPassword} , 請前往郵件信箱查看。`} />
        ) : (
          <Components.Tabs.MainTabs
            tabContent={[
              {
                key: '1',
                title: '會員登入',
                content: <Components.Form.Login processing={processing} onSubmit={handleLoginSubmit} />,
              },
              {
                key: '2',
                title: '忘記密碼',
                content: (
                  <Components.Form.ForgetPassword processing={processing} onSubmit={handleForgetPasswordSubmit} />
                ),
              },
            ]}
          />
        )}
      </div>
    </>
  );
};

export default withBaseContainer(withRouter(LoginContainer));
