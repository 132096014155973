import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Col, Result, Row, Steps } from 'antd';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import Components from '../components';

import CheckMemberCard from '../components/card/checkMember.card';
import { VerifySNFormValues } from '../components/form/verifySN.form';
import { RegisterFormValues } from '../components/form/register.form';

import { DeviceSNService } from '../services/deviceSN.service';
import { DeviceSNMemberDto } from '../transformers/deviceSN.dto';
import { MemberService } from '../services/member.service';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const RegisterSNContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [deviceSNMember, setDeviceSNMember] = useState<DeviceSNMemberDto>();

  const handleVerifySubmit = async (values: VerifySNFormValues): Promise<void> => {
    try {
      setProcessing(true);
      const res = await DeviceSNService.findMemberBySN(values);
      setDeviceSNMember(res);
      setStep(1);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleRegisterSubmit = async (values: RegisterFormValues): Promise<void> => {
    try {
      if (!deviceSNMember) {
        throw new Error('找不到註冊token');
      }
      setProcessing(true);
      await MemberService.registerMember({
        ...values,
        token: deviceSNMember.token,
      });
      setStep(3);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setProcessing(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>科林睡得美會員註冊區 | 遠離睡眠呼吸中止的第一步</title>
        <meta
          name="description"
          content="科林睡得美會員註冊區，感謝您選擇科林睡得美專業睡眠團隊成為您的健康睡眠夥伴！遠離睡眠呼吸中止的第一步"
        />
      </Helmet>
      <div className="banner">
        <h1>SN註冊</h1>
      </div>
      <div className="login-container">
        <div
          style={{
            padding: 30,
            background: '#fbfbfb',
          }}
        >
          <Steps size="small" current={step}>
            <Steps.Step title="填寫SN"></Steps.Step>
            <Steps.Step title="確認資料"></Steps.Step>
            <Steps.Step title="填寫會員資料"></Steps.Step>
            <Steps.Step title="完成"></Steps.Step>
          </Steps>
          <div style={{ marginTop: 30 }}>
            {step === 0 && <Components.Form.VerifySN processing={processing} onSubmit={handleVerifySubmit} />}
            {step === 1 && (
              <>
                <CheckMemberCard deviceSNMember={deviceSNMember} />
                <Row style={{ marginTop: 16 }} justify="center">
                  <Col span={7}>
                    <Button className="btn-second" loading={processing} onClick={() => setStep(0)}>
                      上一步
                    </Button>
                  </Col>
                  <Col span={7}>
                    <Button className="btn-master" loading={processing} onClick={() => setStep(2)}>
                      確定
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {step === 2 && (
              <Components.Form.Register withMobile processing={processing} onSubmit={handleRegisterSubmit} />
            )}
            {step === 3 && <Result status="success" title={`註冊成功`} subTitle={`請到 E-mail 信箱 收取註冊信件`} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default withBaseContainer(withRouter(RegisterSNContainer));
