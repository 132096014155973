import React, { useEffect } from 'react';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

interface Props {
  tabBarExtraContent?: any;
  tabContent?: any[];
  activeKey?: string;
  onChange?: (activeKey: string) => void;
}

const MainTabs: React.FC<Props> = (props) => {
  const { tabBarExtraContent, tabContent, activeKey, onChange } = props;

  return (
    <Tabs
      className="main-tabs"
      defaultActiveKey="1"
      tabBarExtraContent={tabBarExtraContent}
      centered
      activeKey={activeKey}
      onChange={onChange}
    >
      {tabContent?.map((item) => {
        return (
          <TabPane tab={item.title} key={item.key} disabled={item.disabled}>
            {item.content}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default MainTabs;
