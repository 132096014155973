import { APIResponse, BaseAPI } from './base.api';

import { DeviceSNMemberDto } from '../transformers/deviceSN.dto';

export interface FindMemberBySNParams {
  sn: string;
  name: string;
}
export class DeviceSNAPI extends BaseAPI {
  // 顧客資料
  static async findMemberBySN(params: FindMemberBySNParams): Promise<APIResponse<DeviceSNMemberDto>> {
    let result = await BaseAPI.call<DeviceSNMemberDto>({
      path: `/shared/customers/device`,
      method: 'POST',
      data: {
        SN: params.sn,
        name: params.name,
      },
    });

    return result;
  }
}
