import { MemberAchievement } from '../transformers/achievemen.dto';
import { APIResponse, BaseAPI } from './base.api';

export interface AchievementRankParams {
  offset: number;
  limit: number;
}

export class AchievementAPI extends BaseAPI {
  static async rank(params: AchievementRankParams): Promise<APIResponse<MemberAchievement[]>> {
    const result = await this.call<MemberAchievement[]>({
      path: `/subscribed/application/memberAchievementCharts`,
      method: 'GET',
      params: params,
    });
    return result;
    // return {
    //     success: true,
    //     result: {
    //         data: MOCK_RANK,
    //     },
    // };
  }
}

const MOCK_RANK: MemberAchievement[] = [
  {
    member: {
      name: '花初雨',
      memberCode: 'HA0002820',
    },
    score: 32500,
    level: 7,
    achievements: [
      {
        mission: '購機/訂閱60期',
        name: '實踐家',
        value: 2,
        prize: 'Silver',
        score: 30000,
      },
      {
        mission: '介紹朋友HST',
        name: '衛教師',
        value: 1,
        prize: 'Bronze',
        score: 1000,
      },
      {
        mission: '介紹朋友購機/訂閱',
        name: '召喚師',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '提供分享圖文',
        name: '微網紅',
        value: 15,
        prize: 'Bronze',
        score: 1500,
      },
      {
        mission: '提供分享影片',
        name: '影舞者',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '出席分享會/記者會',
        name: '發言人',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '連續每天登入',
        name: '鐵粉',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '每日持續使用',
        name: '長跑者',
        value: 0,
        prize: null,
        score: 0,
      },
    ],
  },
  {
    member: {
      name: '絲莉安',
      memberCode: 'SK0202446',
    },
    score: 5000,
    level: 1,
    achievements: [
      {
        mission: '購機/訂閱60期',
        name: '實踐家',
        value: 1,
        prize: 'Bronze',
        score: 5000,
      },
      {
        mission: '介紹朋友HST',
        name: '衛教師',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '介紹朋友購機/訂閱',
        name: '召喚師',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '提供分享圖文',
        name: '微網紅',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '提供分享影片',
        name: '影舞者',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '出席分享會/記者會',
        name: '發言人',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '連續每天登入',
        name: '鐵粉',
        value: 0,
        prize: null,
        score: 0,
      },
      {
        mission: '每日持續使用',
        name: '長跑者',
        value: 0,
        prize: null,
        score: 0,
      },
    ],
  },
];
