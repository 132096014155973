import React from 'react';
import { Row, Col, Descriptions, Divider, Button, Select, Typography } from 'antd';
import { MemberDto } from '../../../transformers/member.dto';
import moment from 'moment';

interface Props {
  memberData: MemberDto;
  currentSnData: any;
  onChangeCCID?: (value?: any) => void;
  onChangeSubscribe?: (value?: any) => void;
}

const BuyoutDataForm: React.FC<Props> = (props) => {
  const { memberData, currentSnData, onChangeCCID, onChangeSubscribe } = props;

  return (
    <Row className="member-form" justify="space-between">
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="購買日">
            {currentSnData && currentSnData.warrantyStartDate
              ? moment(currentSnData.warrantyStartDate).format('YYYY-MM-DD')
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="購買門市">{currentSnData?.storeName || '-'}</Descriptions.Item>
          <Descriptions.Item label="訂單編號">{currentSnData?.orderCode || '-'}</Descriptions.Item>
        </Descriptions>
      </Col>
      <Divider className="vertical-divider" type="vertical" />
      <Col xs={24} sm={24} md={11}>
        <Descriptions column={1} colon={false}>
          <Descriptions.Item label="APAP機種">{currentSnData?.apapModelName || '-'}</Descriptions.Item>
          <Descriptions.Item label="面罩種類">{currentSnData?.maskKindName || '-'}</Descriptions.Item>
          <Descriptions.Item label="序號">{currentSnData?.sn || '-'}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default BuyoutDataForm;
