import consts from '../consts';

const { STORAGE_ITEM_EMAIL, STORAGE_ITEM_ACCESS_TOKEN, STORAGE_ITEM_MEMBER_ID } = consts;

export class StorageService {
  static get email(): string {
    return localStorage.getItem(STORAGE_ITEM_EMAIL) || '';
  }
  static set email(value) {
    localStorage.setItem(STORAGE_ITEM_EMAIL, value);
  }
  static get memberId(): string {
    return localStorage.getItem(STORAGE_ITEM_MEMBER_ID) || '';
  }
  static set memberId(value) {
    localStorage.setItem(STORAGE_ITEM_MEMBER_ID, value);
  }
  static get token(): string {
    return localStorage.getItem(STORAGE_ITEM_ACCESS_TOKEN) || '';
  }
  static set token(value) {
    localStorage.setItem(STORAGE_ITEM_ACCESS_TOKEN, value);
  }
  static remove(value: string) {
    localStorage.removeItem(value);
  }
}
