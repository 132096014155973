import React, { useEffect, useState } from 'react';
import { Layout, Drawer, Row, Col, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from '../containers/base.container';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { ResmedHeader, ResmedFooter } from '@clinico/clinico-components';
const { Content } = Layout;

interface Props extends WithBaseContainerProps, RouteComponentProps {}

const authService = new AuthService();

const MainLayout: React.FC<Props> = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [isContract, setIsContract] = useState<boolean>(false);

  useEffect(() => {
    const isContract = window.location.href.indexOf('contract') !== -1;
    if (isContract) {
      setIsContract(true);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await authService.logout();
      props.history.push('/login');
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  const onShowDrawer = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  return (
    <>
      <Layout className="main-layout">
        <ResmedHeader
          onLogout={handleLogout}
          logButton={
            StorageService.memberId &&
            !isContract && (
              <Button className="btn-logout" onClick={handleLogout}>
                登出
              </Button>
            )
          }
        />
        <Content className="content">{props.children}</Content>
        <ResmedFooter />
      </Layout>
    </>
  );
};

export default withBaseContainer(withRouter(MainLayout));
