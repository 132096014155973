import React from 'react';
import { Row, Col, Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

export interface LoginFormValues {
  email: string;
  password: string;
}

interface Props {
  processing?: boolean;
  email?: string;
  password?: string;
  onSubmit?: (values: LoginFormValues) => void;
}

const LoginForm: React.FC<Props> = (props) => {
  const { processing, email, password, onSubmit } = props;

  const handleSubmit = (values: { [name: string]: any }): void => {
    onSubmit && onSubmit({ email: values.email, password: values.password });
  };

  return (
    <Form className="form login-form" initialValues={{ email, password }} onFinish={handleSubmit}>
      <Form.Item name="email" rules={[{ required: true, message: '請輸入您的Email' }]}>
        <Input placeholder="Email" disabled={processing} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: '請輸入密碼' }]}>
        <Input type="password" placeholder="請輸入密碼" disabled={processing} />
      </Form.Item>
      <Row justify="center" align="middle" gutter={8}>
        <Col span={7}>
          <Button htmlType="submit" className="btn-master" loading={processing}>
            會員登入
          </Button>
        </Col>
        <Col>
          <Link to="/registerSN">會員註冊</Link>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
