import 'react-app-polyfill/ie11';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, RouteComponentProps, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Layouts from './layouts';
import Containers from './containers';
import moment from 'moment';
import './styles/style.scss';

import zhTW from 'antd/es/locale/zh_TW';
import 'moment/locale/zh-tw';
import GA4React, { useGA4React } from 'ga-4-react';
moment.locale('zhTW');

const Member: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Switch>
      <Route path={`${match.url}/contract`} component={Containers.Contract} />
      <Route path={`${match.url}/:id/changePassword`} component={Containers.Member.ChangePassword} />
      <Route path={`${match.url}/:id`} component={Containers.Member.Detail} />
    </Switch>
  </Layouts.Main>
);

const ResetPassword: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} component={Containers.ResetPassword} />
  </Layouts.Main>
);

const Login: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} exact component={Containers.Login} />
  </Layouts.Main>
);

const eTaxLogin: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} exact component={Containers.eTaxLogin} />
  </Layouts.Main>
);

const RegisterMember: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} exact component={Containers.Register} />
  </Layouts.Main>
);
const RegisterSN: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} exact component={Containers.RegisterSN} />
  </Layouts.Main>
);
const Rank: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Layouts.Main>
    <Route path={`${match.url}`} exact component={Containers.Rank} />
  </Layouts.Main>
);
const Main: FunctionComponent = () => {
  const location = useLocation();

  const ga = useGA4React();
  useEffect(() => {
    if (ga) {
      ga.pageview(window.location);
    }
  }, [location.pathname]);
  return (
    <ConfigProvider locale={zhTW}>
      <Layouts.Base>
        <Route path="/registerMember" component={RegisterMember} />
        <Route path="/registerSN" component={RegisterSN} />
        <Route path="/login" component={Login} />
        <Route path="/eTaxLogin" component={eTaxLogin} />
        <Route path="/member" component={Member} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/rank" component={Rank} />
      </Layouts.Base>
    </ConfigProvider>
  );
};
(async () => {
  try {
    const ga4react = new GA4React(process.env.REACT_APP_GA_CODE!, undefined, ['G-HVW6PGJZW2']);
    await ga4react.initialize();
  } catch {
    console.error('GA log error');
  }
  ReactDOM.render(
    <Router>
      <Main />
    </Router>,
    document.getElementById('root')
  );
})();
