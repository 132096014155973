import React, { useState } from 'react';
import { Result, Alert } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import Components from '../components';
import { LoginFormValues } from '../components/form/login.form';
import { ForgetFormValues } from '../components/form/forgetPassword.form';
import { MemberService } from '../services/member.service';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const authService = new AuthService();

const ETaxLoginContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [resetPassword, setResetPassword] = useState('');

  const handleLoginSubmit = async (values: LoginFormValues): Promise<void> => {
    try {
      setProcessing(true);
      await authService.login(values);

      const memberDetail = await MemberService.findOne();
      if (memberDetail == null || memberDetail.rsId == null) throw new Error('會員資料有誤');

      const queryParams = queryString.parse(props.location.search);
      if (queryParams == null || queryParams.token == null || Array.isArray(queryParams.token))
        throw new Error('Token 資料有誤');

      const rsId = memberDetail?.rsId;
      const token = queryParams?.token;

      const memberDeviceFromApi = await MemberService.memberDeviceAuth(rsId, token);
      if (memberDeviceFromApi == null) throw new Error('找不到會員資料');

      const f: any = document.getElementById('govLargeToSmallForm');
      f.card_ban.value = memberDeviceFromApi.cardBan;
      f.card_no1.value = memberDeviceFromApi.cardNo1;
      f.card_no2.value = memberDeviceFromApi.cardNo2;
      f.card_type.value = memberDeviceFromApi.cardType;
      f.token.value = memberDeviceFromApi.token;
      f.signature.value = memberDeviceFromApi.signature;
      f.action = memberDeviceFromApi.invoiceGovUrl;
      f.submit();
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleForgetPasswordSubmit = async (values: ForgetFormValues): Promise<void> => {
    try {
      await authService.resetPassword(values);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setResetPassword(values.email);
      setShowResult(true);
    }
  };

  return (
    <div className="login-container">
      <form style={{ display: 'none' }} name="govLargeToSmallForm" id="govLargeToSmallForm" method="post">
        <input type="hidden" id="card_ban" name="card_ban" />
        <input type="hidden" id="card_no1" name="card_no1" />
        <input type="hidden" id="card_no2" name="card_no2" />
        <input type="hidden" id="card_type" name="card_type" />
        <input type="hidden" id="token" name="token" />
        <input type="hidden" id="signature" name="signature" />
        <input type="submit" />
      </form>
      <Alert
        message="系統收到您於『財政部電子發票整合服務平台』進行歸戶設定功能，
                請於下方輸入科林睡得美會員帳號及密碼進行認證作業。"
        type="success"
        showIcon
        style={{ marginBottom: 10 }}
      />

      {showResult ? (
        <Result status="success" title={`密碼重設連結已發送到 ${resetPassword} , 請前往郵件信箱查看。`} />
      ) : (
        <Components.Tabs.MainTabs
          tabContent={[
            {
              key: '1',
              title: '會員登入',
              content: <Components.Form.Login processing={processing} onSubmit={handleLoginSubmit} />,
            },
            {
              key: '2',
              title: '忘記密碼',
              content: <Components.Form.ForgetPassword processing={processing} onSubmit={handleForgetPasswordSubmit} />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default withBaseContainer(withRouter(ETaxLoginContainer));
