import { DeviceSNAPI, FindMemberBySNParams } from '../api/deviceSN.api';

export class DeviceSNService {
  static async findMemberBySN(params: FindMemberBySNParams) {
    let res = await DeviceSNAPI.findMemberBySN(params);

    if (!res.success) {
      throw res.error;
    }
    return res.result?.data || undefined;
  }
}
