import Login from './login.container';
import ResetPassword from './resetPassword.container';
import Member from './member';
import Contract from './contract.container';
import eTaxLogin from './eTaxLogin.container';
import Register from './register.container';
import RegisterSN from './registerSN.container';
import Rank from './rank.container';

export default {
  Login,
  ResetPassword,
  Member,
  Contract,
  eTaxLogin,
  Register,
  RegisterSN,
  Rank,
};
