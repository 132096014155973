export enum EnumYesOrNo {
  Yes = 'Y',
  No = 'N',
}

export enum EnumShippingOrderProcess {
  Created = '0',
  Allowed = '1',
  Abandoned = '9',
  Returned = 'R',
  Processing = '5',
  Draw = 'W',
}

export enum EnumShippingOrderStatus {
  Unconfirmed = 'N',
  Abandoned = 'X',
  Confirmed = 'Y',
}

export enum EnumTaxType {
  NormalTaxRate = 'TX',
  ZeroTaxRate = 'TZ',
}

export enum EnumCompany {
  // 科林助聽器
  Clinico = 1,
  // 濰樂聽力中心
  iear = 2,
}

export interface InvoiceDetailDto {
  seqId: number;
  unit: string;
  spec: string;
  qty: number;
  price: number;
  taxRate: number;
  taxType: EnumTaxType;
  untaxedPrice: number;
  taxedPrice: number;
  tax: number;
}

export interface ShippingOrderListDto {
  code: string;
  orderCode: string;
  shippingDates: string;
  untaxedAmount: number;
  taxedAmount: number;
  process: EnumShippingOrderProcess;
  status: EnumShippingOrderStatus;
  signed: EnumYesOrNo;
  posted: EnumYesOrNo;
  invoiceNo: string;
  customerTaxId: string;
}

export interface TransactionDetailDto {
  orderCode: string;
  companyName: string;
  storeCode: string;
  storeName: string;
  storeTaxId: string;
  storeAddress: string;
  storePhone: string;
  customerTaxId: string;
  invoiceNo: string;
  invoiceYear: string;
  invoiceMonth: string;
  invoiceDate: string;
  invoiceTime: string;
  invoiceCheckCode: string;
  taxRate: number;
  taxType: EnumTaxType;
  totalUntaxedPrice: number;
  totalTaxedPrice: number;
  totalTax: number;
  posCode: string;
  lastFourDigitsOfCreditCardNumber: string;
  details: InvoiceDetailDto[];
  invoiceBarcode: string;
  invoiceLeftQrcode: string;
  invoiceRightQrcode: string;
  paymentMemo: string;
}

export interface OrderDetailDto {
  seqId: number;
  name: string;
  code: string;
  unit: string;
  totalQty: number;
  shippedQty: number;
  allowedQty: number;
}

export interface Store {
  id: number;
  code: string;
  name: string;
}

export interface Company {
  id: number;
  name: string;
}

export interface ShippingOrderDetailDto {
  seqId: number;
  name: string;
  spec: string;
  unit: string;
  qty: number;
}

export interface ShippingOrderDetailsDto {
  shippingDate: string;
  process: string;
  store: Store;
  company: Company;
  details: ShippingOrderDetailDto[];
}

export interface ShippingOrderReceiveRecordDto {
  orderDetail: OrderDetailDto[];
  shippingOrderDetails: ShippingOrderDetailsDto[];
}
