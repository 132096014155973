import React from 'react';
import { Card } from 'antd';

import { MemberAchievement } from '../../transformers/achievemen.dto';

interface Props {
  memberAchievement?: MemberAchievement;
}
const XPKingCard: React.FC<Props> = (props) => {
  const { memberAchievement } = props;
  return (
    <Card className="xp-king-card">
      <div className="pin" style={{ top: 8, left: 8 }} />
      <div className="pin" style={{ top: 8, right: 8 }} />
      <div className="pin" style={{ bottom: 8, right: 8 }} />
      <div className="pin" style={{ bottom: 8, left: 8 }} />
      <div style={{ marginBottom: 12 }}>XP王者</div>
      <div className="score">
        <span className="score-heightlight">{memberAchievement?.score ?? 0}</span>XP
      </div>
    </Card>
  );
};
export default XPKingCard;
