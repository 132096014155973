import Tabs from './tabs';
import Form from './form';
import Table from './table';
import Invoice from './invoice';
import Header from './header';
import Footer from './footer';
import Card from './card';

export default {
  Tabs,
  Form,
  Table,
  Invoice,
  Header,
  Footer,
  Card,
};
