import React, { useEffect, useState } from 'react';
import { Layout, Button, Menu, Row, Col, Affix } from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import { StorageService } from '../services/storage.service';
const { Header } = Layout;

interface Props {
  mode: any;
  className: string;
}

const MenuComponent: React.FC<Props> = (props) => {
  const { mode, className } = props;

  return (
    <Menu className={className} mode={mode}>
      <Menu.Item key="news">
        <a href="https://resmed.ear.com.tw/news" rel="noopener noreferrer">
          最新消息
        </a>
      </Menu.Item>
      <Menu.Item key="product">
        <a href="https://resmed.ear.com.tw/products" rel="noopener noreferrer">
          正壓呼吸器及配件
        </a>
      </Menu.Item>
      <Menu.SubMenu
        key="sleepTest"
        title="睡眠檢測"
        onTitleClick={() => {
          window.location.href = 'https://resmed.ear.com.tw/screening';
        }}
      >
        <Menu.Item key="stop-bang">
          <a href="https://stop-bang.resmed.ear.com.tw/" target="_blank" rel="noopener noreferrer">
            Stop-bang 線上問卷
          </a>
        </Menu.Item>
        <Menu.Item key="homeTest">
          <a href="https://resmed.ear.com.tw/inspection?category=37" rel="noopener noreferrer">
            居家睡眠監測
          </a>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="medical"
        title="關於睡眠呼吸中止"
        onTitleClick={() => {
          window.location.href = 'https://resmed.ear.com.tw/osa';
        }}
      >
        <Menu.Item key="symptom">
          <a href="https://resmed.ear.com.tw/sleemedicine?category=54" rel="noopener noreferrer">
            睡眠呼吸中止症狀
          </a>
        </Menu.Item>
        <Menu.Item key="doctor-doc">
          <a href="https://resmed.ear.com.tw/sleemedicine?category=41" rel="noopener noreferrer">
            醫師專欄
          </a>
        </Menu.Item>
        <Menu.Item key="sleep-medical">
          <a href="https://resmed.ear.com.tw/sleemedicine?category=39" rel="noopener noreferrer">
            睡眠醫學
          </a>
        </Menu.Item>
        <Menu.Item key="newsInfo">
          <a href="https://resmed.ear.com.tw/sleemedicine?category=64" rel="noopener noreferrer">
            新聞資訊
          </a>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="recommend">
        <a href="https://resmed.ear.com.tw/recommend" target="_blank" rel="noopener noreferrer">
          好評推薦
        </a>
      </Menu.Item>
      <Menu.Item key="faq">
        <a href="https://resmed.ear.com.tw/faq" target="_blank" rel="noopener noreferrer">
          FAQ
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default MenuComponent;
