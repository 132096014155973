import React, { Fragment } from 'react';
import { Descriptions, Table, Divider, Button, Row, Col, Statistic } from 'antd';
import { EnumCompany } from '../../transformers/shippingOrder.dto';
import PrintComponents from 'react-print-components';

interface Props {
  dataSource?: any;
  onBack: () => void;
}

const InvoiceDetailComponent: React.FC<Props> = (props) => {
  const { dataSource, onBack } = props;

  const columns = [
    {
      title: '型號',
      dataIndex: 'spec',
      key: 'spec',
    },
    {
      title: '數量',
      dataIndex: 'qty',
      key: 'qty',
      className: 'text-center',
      width: 40,
    },
    {
      title: '售價',
      dataIndex: 'price',
      key: 'price',
      className: 'text-right',
      render: (text: number, record: any) => <Statistic className="transaction-price" value={record.price} />,
    },
    {
      dataIndex: 'taxType',
      key: 'taxType',
    },
  ];

  const invoiceLogo = (value: number) => {
    switch (value) {
      case EnumCompany.Clinico:
        return <img className="invoice-logo" src="/images/logo-invoice-clinico.svg" style={{ height: 24 }} />;
      case EnumCompany.iear:
        return <img className="invoice-logo" src="/images/logo-invoice-iear.svg" style={{ height: 24 }} />;
      default:
        return '-';
    }
  };

  return (
    <Fragment>
      <Row justify="center" gutter={[16, 16]}>
        <Col span={4}>
          <Button className="btn-master" onClick={onBack}>
            回交易明細
          </Button>
        </Col>
        <Col span={4}>
          <PrintComponents trigger={<Button className="btn-master">列印</Button>}>
            <div className="invoice">
              {invoiceLogo(dataSource.companyId)}
              <p className="invoice-copy" style={{ display: dataSource.customerTaxId ? 'none' : 'block' }}>
                副本
              </p>
              <p className="invoice-title text-center">電子發票證明聯</p>
              <p className="invoice-dateRange text-center">
                {dataSource?.invoiceYear} {dataSource?.invoiceMonth}
              </p>
              <p className="invoice-no text-center">{dataSource?.invoiceNo}</p>
              <Row justify="space-between">
                <Col span={15}>
                  <p className="invoice-date">
                    {dataSource?.invoiceDate} {dataSource?.invoiceTime}
                  </p>
                </Col>
                {dataSource?.customerTaxId && <Col>格式: 25</Col>}
              </Row>
              {dataSource?.customerTaxId ? (
                <Descriptions column={2}>
                  <Descriptions.Item label="隨機碼">{dataSource?.invoiceCheckCode}</Descriptions.Item>
                  <Descriptions.Item label="總計">
                    <Statistic className="transaction-price" value={dataSource?.totalTaxedPrice} />
                  </Descriptions.Item>
                  <Descriptions.Item label="賣方">{dataSource?.storeTaxId}</Descriptions.Item>
                  <Descriptions.Item label="買方">{dataSource?.customerTaxId}</Descriptions.Item>
                </Descriptions>
              ) : (
                <Descriptions column={2}>
                  <Descriptions.Item label="賣方">{dataSource?.storeTaxId}</Descriptions.Item>
                  <Descriptions.Item label="總計">
                    <Statistic className="transaction-price" value={dataSource?.totalTaxedPrice} />
                  </Descriptions.Item>
                </Descriptions>
              )}
              <div className="invoice-barcode">
                <img src={dataSource?.invoiceBarcode} width="100%" />
              </div>
              <Row className="invoice-qrcode" gutter={16}>
                <Col span={12}>
                  <img src={dataSource?.invoiceLeftQrcode} width="100%" />
                </Col>
                <Col span={12}>
                  <img src={dataSource?.invoiceRightQrcode} width="100%" />
                </Col>
              </Row>
              <p className="invoice-companyName text-center">{dataSource?.companyName}</p>
              <p className="invoice-storeName text-center">{dataSource?.storeName}</p>
              <Table
                className="detail-table"
                size="small"
                rowKey="seqId"
                columns={columns}
                dataSource={dataSource?.details || []}
                pagination={false}
              />
              <Divider className="full-divider" dashed />
              {dataSource?.customerTaxId ? (
                <Descriptions column={1}>
                  <Descriptions.Item label="營業人統編">{dataSource?.storeTaxId}</Descriptions.Item>
                  <Descriptions.Item label="信用卡">{dataSource?.lastFourDigitsOfCreditCardNumber}</Descriptions.Item>
                </Descriptions>
              ) : (
                <Descriptions column={1}>
                  <Descriptions.Item label="信用卡">{dataSource?.lastFourDigitsOfCreditCardNumber}</Descriptions.Item>
                  <Descriptions.Item label="發票">{dataSource?.totalTaxedPrice}</Descriptions.Item>
                </Descriptions>
              )}
              <Divider dashed />
              {dataSource?.customerTaxId ? (
                <Descriptions column={1}>
                  <Descriptions.Item label="合計金額">{dataSource?.totalTaxedPrice}</Descriptions.Item>
                  <Descriptions.Item label="未稅金額">{dataSource?.totalUntaxedPrice}</Descriptions.Item>
                  <Descriptions.Item label="稅額">{dataSource?.totalTax}</Descriptions.Item>
                  <Descriptions.Item label="應稅總額">{dataSource?.totalTaxedPrice}</Descriptions.Item>
                </Descriptions>
              ) : (
                <Descriptions column={1}>
                  <Descriptions.Item label="備註"> </Descriptions.Item>
                  <Descriptions.Item label="會員載具">載具號碼{dataSource?.totalTaxedPrice}</Descriptions.Item>
                </Descriptions>
              )}
              <Divider dashed />
              <p className="invoice-storeAddress">{dataSource?.storeAddress}</p>
              <p className="invoice-storeTel">TEL: {dataSource?.storePhone}</p>
              <Divider className="full-divider">此副本發票不可對獎</Divider>
            </div>
          </PrintComponents>
        </Col>
        <Col span={24}>
          <div className="invoice">
            {invoiceLogo(dataSource.companyId)}
            <p className="invoice-copy" style={{ display: dataSource.customerTaxId ? 'none' : 'block' }}>
              副本
            </p>
            <p className="invoice-title text-center">電子發票證明聯</p>
            <p className="invoice-dateRange text-center">
              {dataSource?.invoiceYear} {dataSource?.invoiceMonth}
            </p>
            <p className="invoice-no text-center">{dataSource?.invoiceNo}</p>
            <Row justify="space-between">
              <Col span={15}>
                <p className="invoice-date">
                  {dataSource?.invoiceDate} {dataSource?.invoiceTime}
                </p>
              </Col>
              {dataSource?.customerTaxId && <Col>格式: 25</Col>}
            </Row>
            {dataSource?.customerTaxId ? (
              <Descriptions column={2}>
                <Descriptions.Item label="隨機碼">{dataSource?.invoiceCheckCode}</Descriptions.Item>
                <Descriptions.Item label="總計">
                  <Statistic className="transaction-price" value={dataSource?.totalTaxedPrice} />
                </Descriptions.Item>
                <Descriptions.Item label="賣方">{dataSource?.storeTaxId}</Descriptions.Item>
                <Descriptions.Item label="買方">{dataSource?.customerTaxId}</Descriptions.Item>
              </Descriptions>
            ) : (
              <Descriptions column={2}>
                <Descriptions.Item label="賣方">{dataSource?.storeTaxId}</Descriptions.Item>
                <Descriptions.Item label="總計">
                  <Statistic className="transaction-price" value={dataSource?.totalTaxedPrice} />
                </Descriptions.Item>
              </Descriptions>
            )}
            <div className="invoice-barcode">
              <img src={dataSource?.invoiceBarcode} width="100%" />
            </div>
            <Row className="invoice-qrcode" gutter={16}>
              <Col span={12}>
                <img src={dataSource?.invoiceLeftQrcode} width="100%" />
              </Col>
              <Col span={12}>
                <img src={dataSource?.invoiceRightQrcode} width="100%" />
              </Col>
            </Row>
            <p className="invoice-companyName text-center">{dataSource?.companyName}</p>
            <p className="invoice-storeName text-center">{dataSource?.storeName}</p>
            <Table
              className="detail-table"
              size="small"
              rowKey="seqId"
              columns={columns}
              dataSource={dataSource?.details || []}
              pagination={false}
            />
            <Divider className="full-divider" dashed />
            {dataSource?.customerTaxId ? (
              <Descriptions column={1}>
                <Descriptions.Item label="營業人統編">{dataSource?.storeTaxId}</Descriptions.Item>
                <Descriptions.Item label="信用卡">{dataSource?.lastFourDigitsOfCreditCardNumber}</Descriptions.Item>
              </Descriptions>
            ) : (
              <Descriptions column={1}>
                <Descriptions.Item label="信用卡">{dataSource?.lastFourDigitsOfCreditCardNumber}</Descriptions.Item>
                <Descriptions.Item label="發票">{dataSource?.totalTaxedPrice}</Descriptions.Item>
              </Descriptions>
            )}
            <Divider dashed />
            {dataSource?.customerTaxId ? (
              <Descriptions column={1}>
                <Descriptions.Item label="合計金額">{dataSource?.totalTaxedPrice}</Descriptions.Item>
                <Descriptions.Item label="未稅金額">{dataSource?.totalUntaxedPrice}</Descriptions.Item>
                <Descriptions.Item label="稅額">{dataSource?.totalTax}</Descriptions.Item>
                <Descriptions.Item label="應稅總額">{dataSource?.totalTaxedPrice}</Descriptions.Item>
              </Descriptions>
            ) : (
              <Descriptions column={1}>
                <Descriptions.Item label="備註"> </Descriptions.Item>
                <Descriptions.Item label="會員載具">載具號碼{dataSource?.totalTaxedPrice}</Descriptions.Item>
              </Descriptions>
            )}
            <Divider dashed />
            <p className="invoice-storeAddress">{dataSource?.storeAddress}</p>
            <p className="invoice-storeTel">TEL: {dataSource?.storePhone}</p>
            <Divider className="full-divider">此副本發票不可對獎</Divider>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default InvoiceDetailComponent;
