import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Layout } from 'antd';
import { AuthService } from '../services/auth.service';

const { Content } = Layout;
const authService = new AuthService();

interface Props extends RouteComponentProps {}

const BaseLayout: React.FC<Props> = (props) => {
  const authOrGoToLoginPage = (): void => {
    switch (props.location.pathname) {
      case '/':
        props.history.push('/login');
        return;
      // case '/login':
      //     authService.clearAuth();
      //     return;
      // default:
      //     if (authService.hasAuth()) {
      //         return;
      //     }
      //     props.history.push('/login');
      // return;
    }
  };

  authOrGoToLoginPage();
  return (
    <Layout>
      <Content>{props.children}</Content>
    </Layout>
  );
};

export default withRouter(BaseLayout);
