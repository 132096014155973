import React from 'react';
import { Row, Col, Button, Form, Input } from 'antd';

export interface ForgetFormValues {
  email: string;
}

interface Props {
  processing?: boolean;
  email?: string;
  onSubmit?: (values: ForgetFormValues) => void;
}

const ForgetPasswordForm: React.FC<Props> = (props) => {
  const { processing, onSubmit } = props;

  const handleSubmit = (values: { [name: string]: any }): void => {
    onSubmit && onSubmit({ email: values.email });
  };

  return (
    <Form className="form login-form" onFinish={handleSubmit}>
      <Form.Item name="email" rules={[{ required: true, message: '請輸入您註冊時使用的Email' }]}>
        <Input placeholder="您註冊時使用的Email" disabled={processing} />
      </Form.Item>

      <Row justify="center">
        <Col span={7}>
          <Button htmlType="submit" className="btn-master" loading={processing}>
            送出
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ForgetPasswordForm;
