import React, { useState, useEffect } from 'react';
import { Result, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from './base.container';
import jwt from 'jsonwebtoken';
import Components from '../components';
import { FormValues } from '../components/form/resetPassword.form';
import { MemberService } from '../services/member.service';
import { AuthService } from '../services/auth.service';

interface Props extends RouteComponentProps, WithBaseContainerProps {}

const ResetPasswordContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    // 檢查token是否失效
    let url = window.location.href;
    let token = new URL(url).searchParams.get('token');
    let jwtToken = token && jwt.decode(token); //解析token
    let dateTime = new Date().getTime();
    let timestamp = Math.floor(dateTime / 1000);
    // @ts-ignore
    if (jwtToken && timestamp > jwtToken.exp) {
      setInvalid(true);
    }
  }, []);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    try {
      setProcessing(true);
      let token = new URL(window.location.href).searchParams.get('token');
      if (token) {
        values['token'] = token;
      }
      await MemberService.forgetAndChangePassword(values);
      setResetSuccess(true);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleCancel = () => {
    props.history.push('/login');
  };

  return (
    <div className="login-container">
      {invalid ? (
        <Result
          title="重置密碼連結已過期"
          extra={
            <Button type="primary" href="/login">
              重新寄送
            </Button>
          }
        />
      ) : resetSuccess ? (
        <Result
          status="success"
          title="密碼重設成功"
          extra={
            <Button type="primary" href="/login">
              回登入頁
            </Button>
          }
        />
      ) : (
        <Components.Tabs.MainTabs
          tabContent={[
            {
              key: '1',
              title: '修改密碼',
              content: <Components.Form.resetPassword onSubmit={handleSubmit} onCancel={handleCancel} />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default withBaseContainer(withRouter(ResetPasswordContainer));
