import React from 'react';
import { Row, Col, Button, Form, Input } from 'antd';

export interface VerifySNFormValues {
  sn: string;
  name: string;
}

interface Props {
  processing?: boolean;
  onSubmit?: (values: VerifySNFormValues) => void;
}

const VerifySNForm: React.FC<Props> = (props) => {
  const { processing, onSubmit } = props;
  const handleSubmit = (values: VerifySNFormValues): void => {
    onSubmit && onSubmit(values);
  };

  return (
    <Form className="form login-form" onFinish={handleSubmit}>
      <Form.Item name="sn" rules={[{ required: true, message: '請輸入您的主機序號(SN)' }]}>
        <Input placeholder="請輸入您的主機序號(SN)" disabled={processing} />
      </Form.Item>
      <Form.Item name="name" rules={[{ required: true, message: '請輸入您的姓名' }]}>
        <Input placeholder="請輸入您的姓名" disabled={processing} />
      </Form.Item>
      <Row justify="center">
        <Col span={7}>
          <Button htmlType="submit" className="btn-master" loading={processing}>
            驗證
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default VerifySNForm;
