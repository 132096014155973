import React, { useEffect } from 'react';
import { Layout, Row, Col, Divider } from 'antd';
import { MailFilled, PhoneFilled } from '@ant-design/icons';
const { Footer } = Layout;

interface Props {}

const FooterComponent: React.FC<Props> = (props) => {
  return (
    <>
      <Footer className="footer">
        <Row className="link-section" justify="center" gutter={32}>
          <Col xs={24} sm={12} md={12} lg={6}>
            <h3>探索產品</h3>
            <a href="https://resmed.ear.com.tw/products/detail/24" rel="noopener noreferrer">
              AirSense 10 Autoset
            </a>
            <a href="https://resmed.ear.com.tw/products/detail/23" rel="noopener noreferrer">
              AirSense 10 Autoset for Her
            </a>
            <a href="https://resmed.ear.com.tw/products?category=79" rel="noopener noreferrer">
              AirMini
            </a>
            <a href="https://resmed.ear.com.tw/products?category=71" rel="noopener noreferrer">
              耗材與配件
            </a>
            <a href="http://resmed.ear.com.tw/products?category=75" rel="noopener noreferrer">
              威淨連續式呼吸器配件清潔儀
            </a>
            <a href="https://resmed.ear.com.tw/news/detail/122" rel="noopener noreferrer">
              正壓呼吸器訂閱制
            </a>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <h3>支援服務</h3>
            <a href="https://resmed.ear.com.tw/faq" rel="noopener noreferrer">
              常見問題
            </a>
            <a href="https://member.resmed.ear.com.tw/login" target="_blank" rel="noopener noreferrer">
              會員登入專區
            </a>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <h3>關於我們</h3>
            <a href="https://resmed.ear.com.tw/news/detail/108" rel="noopener noreferrer">
              關於科林睡得美
            </a>
            <a href="https://booking.resmed.ear.com.tw/stores" target="_blank" rel="noopener noreferrer">
              服務據點
            </a>
            <a href="https://resmed.ear.com.tw/osa?category=64" rel="noopener noreferrer">
              媒體專區
            </a>
            <a href="https://resmed.ear.com.tw/contact" rel="noopener noreferrer">
              聯絡我們
            </a>
            <a href="https://resmed.ear.com.tw/news/detail/97" rel="noopener noreferrer">
              特約企業
            </a>
            <a href="https://resmed.ear.com.tw/r_cooperation" rel="noopener noreferrer">
              友善連結
            </a>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6}>
            <h3>追蹤我們</h3>
            <p>
              <MailFilled style={{ marginRight: 10 }} />
              service.resmed@clinico.com.tw
            </p>
            <p>
              <PhoneFilled style={{ marginRight: 10 }} />
              0800-551-188
            </p>
            <p>
              客服時間：09:00AM-18:00PM<br></br>(週一至週五，假日及國定假日除外)
            </p>
            <Row justify="start">
              <Col span={4}>
                <a href="https://www.facebook.com/ResmedTW/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/fbIcon.svg" width="30" />
                </a>
              </Col>
              <Col span={4}>
                <a href="https://www.instagram.com/resmed_tw/" target="_blank" rel="noopener noreferrer">
                  <img src="/images/instagramIcon.svg" width="30" />
                </a>
              </Col>
              <Col span={4}>
                <a
                  href="https://www.youtube.com/channel/UCyyDOE7SljRIk4A7DQjSmUQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/images/youtubeIcon.svg" width="30" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
      <div className="copyright">
        <p>COPYRIGHT © 科林睡得美 保留一切權利 ｜ RESMED | ALL RIGHTS RESERVED.</p>
        <p className="footer-link">
          <a href="https://booking.resmed.ear.com.tw/privacy" target="_blank" rel="noopener noreferrer">
            隱私權政策
          </a>
          <span> | </span>
          <a type="link" href="https://member.resmed.ear.com.tw/member/contract" target="_parent">
            訂閱條款
          </a>
        </p>
      </div>
    </>
  );
};

export default FooterComponent;
