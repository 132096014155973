import Login from './login.form';
import Member from './member';
import ForgetPassword from './forgetPassword.form';
import resetPassword from './resetPassword.form';
import Register from './register.form';
import VerifySN from './verifySN.form';

export default {
  Login,
  Member,
  ForgetPassword,
  resetPassword,
  Register,
  VerifySN,
};
