import React, { useState, useEffect } from 'react';
import { Spin, Row, Col, Select, Result, Divider, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { withBaseContainer, WithBaseContainerProps } from '../base.container';
import Components from '../../components';
import AchievementBoard from '../../components/achievement/achievementBoard';
import CCIDModifyModalContainer from './ccidModify.container';
import { MemberService } from '../../services/member.service';

import {
  MemberDto,
  EnumMemberType,
  EnumCareItem,
  MemberBuyingDto,
  MemberUsageRateDto,
  MemberUsageStatusDto,
} from '../../transformers/member.dto';
import {
  ShippingOrderListDto,
  ShippingOrderDetailDto,
  ShippingOrderReceiveRecordDto,
} from '../../transformers/shippingOrder.dto';
import moment from 'moment';
import { MemberAchievement } from '../../transformers/achievemen.dto';

interface Props extends RouteComponentProps<{ memberId: string }>, WithBaseContainerProps {}

const MemberDetailContainer: React.FC<Props> = (props) => {
  const [processing, setProcessing] = useState(false);
  const [isNoOrderData, setIsNoOrderData] = useState(false);
  const [memberData, setMemberData] = useState<MemberDto | any>({});
  const [memberAchievementData, setmemberAchievementData] = useState<MemberAchievement>();
  const [buyingList, setBuyingList] = useState<MemberBuyingDto[] | undefined>([]);
  const [usageHistoriesData, setUsageHistoriesData] = useState<MemberUsageStatusDto[] | undefined>([]);
  const [usageRateData, setUsageRateData] = useState<MemberUsageRateDto | undefined>();
  const [currentSnData, setCurrentSnData] = useState<MemberBuyingDto | undefined>();
  const [shippingOrderData, setShippingOrderData] = useState<ShippingOrderListDto[]>([]);
  const [openChangeCCIDModal, setOpenChangeCCIDModal] = useState<boolean>(false);
  const [changeCCIDSuccess, setChangeCCIDSuccess] = useState<boolean>(false);
  const [openInvoicePage, setInvoicePage] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<ShippingOrderDetailDto>();
  const [shippingOrderDetailData, setShippingOrderDetailData] = useState<ShippingOrderReceiveRecordDto[] | any>([]);
  const [noUsageData, setNoUsageDate] = useState(false);
  const [searchMonth, setSearchMonth] = useState<any>(moment().format('YYYYMM'));
  // const [openSubscribeModifyModal, setOpenSubscribeModifyModal] = useState<boolean>(false);
  // const [ subscribeType, setSubscribeType ] = useState('');

  const query = async () => {
    try {
      setProcessing(true);
      let memberDataFromApi = await MemberService.findOne();
      let buyingListFromApi = await MemberService.findBuyingList();
      let memberAchievementFromApi = await MemberService.memberAchievement();

      if (!buyingListFromApi?.length) {
        setIsNoOrderData(true);
      }
      let shippingOrderDataFromApi = await MemberService.findAllOfShippingOrder();
      if (shippingOrderDataFromApi.length > 0) {
        let shippingReceiveRecordFromApi = await MemberService.shippingOrderReceiveRecord(
          shippingOrderDataFromApi[0].orderCode
        );
        setShippingOrderDetailData(shippingReceiveRecordFromApi);
      }
      if (buyingListFromApi && buyingListFromApi.length > 0) {
        let memberUsageRateFromApi = await MemberService.memberUsageRate({
          customerCode: buyingListFromApi?.[0].memberId,
          sn: buyingListFromApi?.[0].sn,
        });
        setUsageRateData(memberUsageRateFromApi);
      }
      setMemberData(memberDataFromApi);
      setmemberAchievementData(memberAchievementFromApi);
      setBuyingList(buyingListFromApi);
      setCurrentSnData(buyingListFromApi?.[0]);
      setShippingOrderData(shippingOrderDataFromApi);
    } catch (error) {
      props.showErrorModal(error, error.type);
      if (error.status == 400) {
        setIsNoOrderData(true);
      }
    } finally {
      setProcessing(false);
    }
  };

  const queryUsage = async (sn: string, searchMonth: string) => {
    try {
      setProcessing(true);
      let memberUsageHistoriesFromApi = await MemberService.memberUsageStatus({
        customerCode: currentSnData ? currentSnData.memberId : '',
        sn: sn,
        searchDate: searchMonth || moment().format('YYYYMM'),
      });
      setUsageHistoriesData(memberUsageHistoriesFromApi);
      setNoUsageDate(false);
    } catch (error) {
      if (error.status == 404 || error.status == 400) {
        setNoUsageDate(true);
      } else {
        props.showErrorModal(error, error.type);
      }
    } finally {
      setProcessing(false);
    }
  };

  const queryChangeBuyingItem = async (value: any) => {
    try {
      setProcessing(true);
      let memberUsageRateFromApi = await MemberService.memberUsageRate({
        customerCode: currentSnData ? currentSnData.memberId : '',
        sn: value,
      });
      queryUsage(value, searchMonth);
      setUsageRateData(memberUsageRateFromApi);
      setNoUsageDate(false);
    } catch (error) {
      if (error.status == 404 || error.status == 400) {
        setNoUsageDate(true);
      } else {
        props.showErrorModal(error, error.type);
      }
    } finally {
      setProcessing(false);
    }
  };

  const hanldeChangeMonth = (value: any) => {
    if (value) setSearchMonth(moment(value).format('YYYYMM'));
  };

  const remindCustomer = () => {
    const today = moment();
    currentSnData?.remindData.forEach((item) => {
      const remindStartDate = moment(item.remindDate).add(-3, 'week');
      const needRemind = today.isSameOrAfter(remindStartDate);
      if (!needRemind) {
        return;
      }

      if (item.itemCode === EnumCareItem.main && currentSnData?.sn) {
        Modal.info({
          title: '提醒',
          content: (
            <>
              <p>您的正壓呼吸器每半年保養和壓力校正時間快到囉！</p>
              <p>門市人員將會主動與您聯繫，也歡迎您自行預約到店保養</p>
            </>
          ),
          width: 460,
          closable: true,
          okText: '知道了，不再提醒',
          onOk: async () => {
            try {
              await MemberService.updateRemindDate({ id: item.id });
            } catch (error) {
              props.showErrorModal(error, error.type);
            }
          },
        });
      } else {
        Modal.info({
          title: '提醒',
          content: (
            <p>
              提醒您！您目前所使用的<span className="receive-yet">{careItem(item.itemCode)}</span>
              已超過原廠建議的更換時間囉！建議您到店選購更換，以常保衛生及健康
            </p>
          ),
          width: 460,
          closable: true,
          okText: '知道了，不再提醒',
          onOk: async () => {
            try {
              await MemberService.updateRemindDate({ id: item.id });
            } catch (error) {
              props.showErrorModal(error, error.type);
            }
          },
        });
      }
    });
  };

  useEffect(() => {
    query();
  }, [props.match.params.memberId]);

  useEffect(() => {
    if (currentSnData) {
      queryUsage(currentSnData.sn, searchMonth);
      remindCustomer();
    }
  }, [searchMonth]);

  useEffect(() => {
    if (currentSnData) {
      queryChangeBuyingItem(currentSnData.sn);
      remindCustomer();
    }
  }, [currentSnData]);

  const handleChangeBuyingItem = (value: string) =>
    setCurrentSnData(
      buyingList?.find((item: any) => {
        const selectValue = `${item.soCode}_${item.sn}`;
        return selectValue === value;
      })
    );

  // const handleChangeSubscribe = (value?: any) => {
  //     setOpenSubscribeModifyModal(true);
  //     setSubscribeType(value)
  // }

  const handleChangeCCID = (values?: any) => {
    setOpenChangeCCIDModal(true);
  };

  const handleSubmit = async (value?: any) => {
    try {
      setProcessing(true);
      value['rsId'] = memberData?.rsId;
      value['year'] = moment(value.expiredDate).format('YY');
      value['month'] = moment(value.expiredDate).format('MM');
      await MemberService.updateCreditCard(value);
      setChangeCCIDSuccess(true);
      query();
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setProcessing(false);
    }
  };
  const handleCancel = () => {
    setOpenChangeCCIDModal(false);
    setChangeCCIDSuccess(false);
  };

  const handleOpenInvoiceDetail = async (value: any) => {
    try {
      let invoiceDataFromApi = await MemberService.shippingOrderDetail(value);
      setInvoiceData(invoiceDataFromApi);
    } catch (error) {
      props.showErrorModal(error);
    } finally {
      setInvoicePage(true);
    }
  };

  const handleMemberDevice = async (value: string) => {
    try {
      let memberDeviceFromApi = await MemberService.memberDevice(value);
      if (memberDeviceFromApi) {
        const f: any = document.getElementById('govForm');
        f.card_ban.value = memberDeviceFromApi.cardBan;
        f.card_no1.value = memberDeviceFromApi.cardNo1;
        f.card_no2.value = memberDeviceFromApi.cardNo2;
        f.card_type.value = memberDeviceFromApi.cardType;
        f.back_url.value = memberDeviceFromApi.backUrl;
        f.token.value = memberDeviceFromApi.token;
        f.action = memberDeviceFromApi.invoiceGovUrl;
        f.submit();
      }
    } catch (error) {
      props.showErrorModal(error);
    }
  };

  const handleBack = () => {
    setInvoicePage(false);
  };

  const memberType = (value: number) => {
    switch (value) {
      case EnumMemberType.buyingOut:
        return '購買';
      case EnumMemberType.subscribe:
        return '訂閱';
      default:
        return '-';
    }
  };

  const careItem = (value: number) => {
    switch (value) {
      case EnumCareItem.mask:
        return '面罩';
      case EnumCareItem.tube:
        return '加溫管路';
      case EnumCareItem.waterBox:
        return '水盒';
      case EnumCareItem.filterCotton:
        return '濾棉';
      default:
        return '-';
    }
  };

  return (
    <div className="member-container">
      <form style={{ display: 'none' }} name="govForm" id="govForm" method="post" target="_blank">
        <input type="hidden" id="card_ban" name="card_ban" />
        <input type="hidden" id="card_no1" name="card_no1" />
        <input type="hidden" id="card_no2" name="card_no2" />
        <input type="hidden" id="card_type" name="card_type" />
        <input type="hidden" id="back_url" name="back_url" />
        <input type="hidden" id="token" name="token" />
        <input type="submit" />
      </form>

      <Spin spinning={processing}>
        {/* 更改訂閱方案先隱藏 */}
        {/* <SubscribeModifyContainer 
                        processing={processing}
                        visible={openSubscribeModifyModal} 
                        subscribeType={subscribeType}
                        onSubmit={handleSubmit} 
                        onCancel={handleCancel} /> */}
        <CCIDModifyModalContainer
          processing={processing}
          visible={openChangeCCIDModal}
          changeCCIDSuccess={changeCCIDSuccess}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
        {isNoOrderData && <Result status="warning" title="查無資料" />}
        {!isNoOrderData && (
          <Row gutter={16}>
            {currentSnData && (
              <Col xs={24} sm={24} md={24} lg={7} xl={6}>
                <Components.Card.MemberInfoCard
                  memberData={memberData}
                  currentSnData={currentSnData}
                  onMemberDevice={handleMemberDevice}
                />
              </Col>
            )}

            <Col xs={24} sm={24} md={24} lg={17} xl={18}>
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={16}>
                  <Components.Card.DashboardCard
                    memberData={memberData}
                    buyingList={buyingList}
                    searchMonth={searchMonth}
                    noUsageData={noUsageData}
                    currentSnData={currentSnData}
                    usageRateData={usageRateData}
                    usageHistoriesData={usageHistoriesData}
                    onChangeMonth={hanldeChangeMonth}
                    onChangeBuyingItem={handleChangeBuyingItem}
                  />
                </Col>
                <Col xs={24} lg={8}>
                  <AchievementBoard memberAchievement={memberAchievementData} />
                </Col>
                <Divider />
                <Col xs={24}>
                  {currentSnData?.memberType === EnumMemberType.subscribe ? (
                    <Components.Tabs.MainTabs
                      tabContent={[
                        {
                          key: '1',
                          title: `${memberType(currentSnData?.memberType)}資料`,
                          content: (
                            <Components.Form.Member.SubscribeData
                              memberData={memberData}
                              currentSnData={currentSnData}
                              onChangeCCID={handleChangeCCID}
                            />
                          ),
                        },
                        {
                          key: '2',
                          title: '領取紀錄',
                          content: (
                            <Components.Form.Member.ReceiveRecord
                              memberData={memberData}
                              currentSnData={currentSnData}
                              dataSource={shippingOrderDetailData}
                            />
                          ),
                        },
                        {
                          key: '3',
                          title: '交易明細',
                          content: openInvoicePage ? (
                            <Components.Invoice.Invoice dataSource={invoiceData} onBack={handleBack} />
                          ) : (
                            <Components.Table.Transaction
                              dataSource={shippingOrderData}
                              onOpenInvoiceDetail={handleOpenInvoiceDetail}
                            />
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <Components.Tabs.MainTabs
                      tabContent={[
                        {
                          key: '1',
                          title: `${currentSnData && memberType(currentSnData.memberType)}資料`,
                          content: (
                            <Components.Form.Member.BuyoutData
                              memberData={memberData}
                              currentSnData={currentSnData}
                              onChangeCCID={handleChangeCCID}
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default withBaseContainer(withRouter(MemberDetailContainer));
