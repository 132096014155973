import React, { useState } from 'react';
import { Row, Col, Modal, Form, Input, DatePicker, Divider, Checkbox, Button } from 'antd';
import { PhoneFilled } from '@ant-design/icons';

interface CollectionCreateFormProps {
  processing: boolean;
  visible: boolean;
  subscribeType: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}
const SubscribeModifyModalContainer: React.FC<CollectionCreateFormProps> = (props) => {
  const { processing, visible, subscribeType, onSubmit, onCancel } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      className="subscribeModifyModal"
      visible={visible}
      width={382}
      closable={false}
      okText="變更"
      cancelText="取消"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <p style={{ textAlign: 'center' }}>訂閱方案</p>
      <h3 style={{ textAlign: 'center' }}>
        合約 <span className="subscribe-info">{subscribeType}</span> 期
      </h3>
      <Row justify="space-around" gutter={[8, 16]}>
        <Col span={12}>起始日： 2020/01/25</Col>
        <Col span={12}>截止日： 2022/01/25</Col>
      </Row>
      <Form form={form} layout="horizontal">
        <Form.Item
          label="訂閱變更生效日"
          rules={[{ required: true, message: 'Please input the title of collection!' }]}
        >
          <DatePicker style={{ width: '100%' }} bordered={false} />
        </Form.Item>
        <Divider />
        <Form.Item>
          <Checkbox.Group>
            <Row gutter={[8, 16]}>
              <Col span={12}>
                <Checkbox value="change">
                  變更合約為 <span className="subscribe-info">{subscribeType == '24' ? '60' : '24'}</span> 期
                </Checkbox>
              </Col>
              <Col span={12}>
                {subscribeType == '24' ? (
                  <div>
                    <span className="subscribe-item">升級差價：</span>{' '}
                    <span className="subscribe-info subscribe-item-price">10800</span> 元
                  </div>
                ) : (
                  <div>
                    請與服務人員聯繫 <PhoneFilled className="contactIcon" />
                  </div>
                )}
              </Col>
              <Col span={12}>
                <Checkbox value="stop">
                  終止合約 <span className="subscribe-info">2/{subscribeType}</span> 期
                </Checkbox>
              </Col>
              <Col span={12}>
                <span className="subscribe-item">違約金：</span>{' '}
                <span className="subscribe-info subscribe-item-price">9399</span> 元
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubscribeModifyModalContainer;
