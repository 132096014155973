import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  Badge,
  Divider,
  Empty,
  Descriptions,
  DatePicker,
  Tag,
  Dropdown,
  Button,
  Menu,
  Popconfirm,
} from 'antd';
import { RingProgress, Column } from '@ant-design/charts';
import Icon, { CaretDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { MemberDto, EnumMemberType } from '../../transformers/member.dto';
import moment from 'moment';

interface Props {
  memberData: MemberDto;
  buyingList: any;
  currentSnData: any;
  usageRateData: any;
  usageHistoriesData: any;
  noUsageData: boolean;
  onChangeMonth: any;
  onChangeBuyingItem: any;
  searchMonth: string;
}

const DashboardCard: React.FC<Props> = (props) => {
  const {
    memberData,
    buyingList,
    currentSnData,
    noUsageData,
    usageRateData,
    usageHistoriesData,
    onChangeMonth,
    onChangeBuyingItem,
    searchMonth,
  } = props;

  const [showLeakToolTip, setShowLeakToolTip] = useState(false);
  const [showAHIToolTip, setShowAHIToolTip] = useState(false);
  const [showUsageToolTip, setShowUsageToolTip] = useState(false);
  const [showUsageHoursToolTip, setShowUsageHoursToolTip] = useState(false);

  const memberType = (value: number) => {
    switch (value) {
      case 2:
        return <Tag color="blue">買斷</Tag>;
      case 3:
        return <Tag color="green">訂閱</Tag>;
      default:
        return '-';
    }
  };

  const rsStatus = (value: any) => {
    switch (value) {
      case '1':
        return '新申請';
      case '2':
        return '訂閱期滿';
      case '9':
        return '取消訂閱';
      default:
        return '-';
    }
  };

  const productImage = (value: string) => {
    switch (value) {
      case 'AIRSENSE10 AUTOSET ANZ TRI 4G':
        return '/images/airsense10.jpg';
      case 'AIRSENSE10 ASFH ANZ TRI 4G':
        return '/images/airsense10ForHer.jpg';
      case 'AirMini AutoSet W APAC':
        return '/images/airmini.jpg';
      default:
        return '/images/airsense10.jpg';
    }
  };
  const usageHoursColor = (value: any) => {
    const valueToNumber = Number(value);
    if (valueToNumber >= 4) {
      return '#8bc34a';
    } else {
      return '#eb2f96';
    }
  };
  const useHoursConfig = {
    height: 100,
    width: 100,
    percent: 1,
    color: [usageHoursColor(usageRateData?.latestCustomerTherapyData?.usageHours), '#eb2f96'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      content: {
        style: {
          fontSize: '16px',
          fontWeight: 500,
        },
        formatter: function formatter() {
          if (
            usageRateData &&
            usageRateData.latestCustomerTherapyData &&
            usageRateData.latestCustomerTherapyData.usageHours
          ) {
            return `${usageRateData.latestCustomerTherapyData.usageHours}/小時`;
          } else {
            return '0/小時';
          }
        },
      },
    },
  };
  const usageRateColor = (value: any) => {
    const valueToNumber = Number(value) * 100;
    if (valueToNumber >= 70) {
      return '#8bc34a';
    } else if (valueToNumber >= 50 && valueToNumber < 70) {
      return '#ffed00';
    } else if (valueToNumber >= 30 && valueToNumber < 49) {
      return '#ff8300';
    } else {
      return '#eb2f96';
    }
  };
  const overFourHoursConfig = {
    height: 100,
    width: 100,
    percent: 1,
    color: [usageRateColor(usageRateData?.usedRate), '#8bc34a'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      content: {
        style: {
          fontSize: '16px',
          fontWeight: 500,
        },
        formatter: function formatter() {
          return `${usageRateData?.usedDays ? usageRateData.usedDays : 0}/天`;
        },
      },
    },
  };

  const usageRateConfig = {
    height: 100,
    width: 100,
    percent: usageRateData?.usedRate || 0,
    color: [usageRateColor(usageRateData?.usedRate), '#E8EDF3'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      content: {
        style: {
          fontSize: '16px',
          fontWeight: 500,
        },
      },
    },
  };

  const leakVentColor = (value: any) => {
    const valueToNumber = Number(value);
    if (valueToNumber >= 41) {
      return '#eb2f96';
    } else if (valueToNumber >= 24 && valueToNumber < 41) {
      return '#ffed00';
    } else {
      return '#8bc34a';
    }
  };

  const AHIColor = (value: any) => {
    const valueToNumber = Number(value);
    if (valueToNumber >= 21) {
      return '#eb2f96';
    } else if (valueToNumber >= 11 && valueToNumber < 21) {
      return '#ff8300';
    } else if (valueToNumber >= 5 && valueToNumber < 11) {
      return '#ffed00';
    } else {
      return '#8bc34a';
    }
  };

  const threeDaysAverageLeakVentConfig = {
    height: 100,
    width: 100,
    percent: 1,
    color: [leakVentColor(usageRateData?.threeDaysAverageLeakVent), '#E8EDF3'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      content: {
        style: {
          fontSize: '16px',
          fontWeight: 500,
        },
        formatter: function formatter() {
          return `${usageRateData?.threeDaysAverageLeakVent || '-'} L/分`;
        },
      },
    },
  };

  const threeDaysAverageAhiConfig = {
    height: 100,
    width: 100,
    percent: 1,
    color: [AHIColor(usageRateData?.threeDaysAverageAHI), '#E8EDF3'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      content: {
        style: {
          fontSize: '16px',
          fontWeight: 500,
        },
        formatter: function formatter() {
          return `${usageRateData?.threeDaysAverageAHI || '-'}/小時`;
        },
      },
    },
  };

  let historyData: any = [];

  /**
   *  有BUG重構過，其他不敢動
   */
  if (usageHistoriesData && usageHistoriesData.length > 0) {
    const monthDays = moment(usageHistoriesData[0].date).daysInMonth();
    // index 0 ~ 30 對應 1 ~ 31日
    const hourMap = new Array(monthDays).fill(0);

    // fill data
    usageHistoriesData.forEach((item: any) => {
      hourMap[new Date(item.date).getDate() - 1] = Number(item.usage);
    });
    hourMap.forEach((value, index) => {
      historyData.push({ day: `${index + 1}`, hours: value, type: value >= 4 ? '大於4小時' : '小於4小時' });
    });
  }
  const productMenu = (
    <Menu onClick={(e) => onChangeBuyingItem(e.key)}>
      {buyingList.map((item: any) => {
        return <Menu.Item key={`${item.soCode}_${item.sn}`}>{item.apapModelName}</Menu.Item>;
      })}
    </Menu>
  );

  const leakVentText = () => {
    return (
      <>
        <p>
          <Badge color="#8bc34a" text="漏氣 < 24 L/分" />
        </p>

        <p>
          <Badge color="#ffed00" text="漏氣介於 24-40 L/分" />
        </p>
        <p>
          <Badge color="#eb2f96" text="漏氣 > 40 L/分" />
        </p>
      </>
    );
  };

  const AHIText = () => {
    return (
      <>
        <p>
          <Badge color="#8bc34a" text="AHI < 5/小時" />
        </p>
        <p>
          <Badge color="#ffed00" text="AHI介於 5-10/小時" />
        </p>
        <p>
          <Badge color="#ff8300" text="AHI介於 11-20/小時" />
        </p>
        <p>
          <Badge color="#eb2f96" text="AHI > 20/小時" />
        </p>
      </>
    );
  };
  const usageRateText = () => {
    return (
      <>
        <p>
          <Badge color="#8bc34a" text="持續使用率 > 70%" />
        </p>

        <p>
          <Badge color="#ffed00" text="持續使用率 50%-69%" />
        </p>
        <p>
          <Badge color="#ff8300" text="持續使用率 30%-49%" />
        </p>
        <p>
          <Badge color="#eb2f96" text="持續使用率 < 30%" />
        </p>
      </>
    );
  };
  const usageHoursText = () => {
    return (
      <>
        <p>
          <Badge color="#8bc34a" text="使用時數 >= 4小時" />
        </p>
        <p>
          <Badge color="#eb2f96" text="使用時數 < 4小時" />
        </p>
      </>
    );
  };

  return (
    <Card bodyStyle={{ padding: 16, border: '5px solid #ccc' }}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
          <img src={productImage(currentSnData?.itemName)} width="100%" />
        </Col>
        <Col xs={24} sm={12} md={12} lg={18} xl={18}>
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
            title={
              <div>
                {memberType(currentSnData?.memberType)}
                <Dropdown className="product-switch" overlay={productMenu}>
                  <Button>
                    <Row justify="space-between">
                      <Col>
                        <p>機型: {currentSnData?.apapModelName}</p>
                      </Col>
                      <Col>
                        <CaretDownOutlined />
                      </Col>
                    </Row>
                  </Button>
                </Dropdown>
              </div>
            }
            colon={false}
          >
            <Descriptions.Item label="序號">{currentSnData?.sn || '-'}</Descriptions.Item>
            {currentSnData?.memberType == EnumMemberType.buyingOut && (
              <Descriptions.Item label="訂單編號">{currentSnData?.orderCode}</Descriptions.Item>
            )}
            {currentSnData?.memberType == EnumMemberType.subscribe && (
              <>
                <Descriptions.Item label="訂閱單號">{currentSnData?.rsId || '-'}</Descriptions.Item>
                <Descriptions.Item label="訂閱期數">
                  <span className="subscribe-info"> {currentSnData?.maxPeriod || '-'} </span> 期
                </Descriptions.Item>
                <Descriptions.Item label="已使用期數">
                  <span className="subscribe-info">
                    {' '}
                    {currentSnData?.currentPeriod || '-'}/{currentSnData?.maxPeriod || '-'}{' '}
                  </span>{' '}
                  期
                </Descriptions.Item>
                <Descriptions.Item label="申請狀態">
                  <span className="subscribe-info">{rsStatus(currentSnData?.rsStatus)}</span>
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </Col>
      </Row>
      <Divider>
        使用狀況分析 <span style={{ color: '#b3b3b3', fontWeight: 400 }}></span>
      </Divider>
      {!usageRateData ? (
        <Empty />
      ) : (
        <Row gutter={16} justify="space-around" style={{ marginBottom: 40 }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={4}>
            <div onClick={(e) => setShowUsageHoursToolTip(!showUsageHoursToolTip)}>
              <Popconfirm placement="top" icon={null} title={usageHoursText} visible={showUsageHoursToolTip}>
                <p style={{ textAlign: 'center', minHeight: 60 }}>
                  <Badge color="pink" text="使用時數" />
                  <ExclamationCircleOutlined style={{ color: '#faad14', marginLeft: 5 }} />
                </p>
              </Popconfirm>
            </div>
            <RingProgress style={{ display: 'block', margin: '0 auto' }} {...useHoursConfig} />
            <p style={{ color: '#b3b3b3', fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
              {usageRateData && usageRateData.latestCustomerTherapyData && usageRateData.latestCustomerTherapyData.date
                ? `* ${moment(usageRateData.latestCustomerTherapyData.date).format('YYYY/MM/DD')}`
                : `* ${moment().add(-2, 'days').format('YYYY/MM/DD')}`}
            </p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={5}>
            <p style={{ textAlign: 'center', minHeight: 60 }}>
              <Badge color="green" text="使用超過4小時天數" />
            </p>
            <RingProgress style={{ display: 'block', margin: '0 auto' }} {...overFourHoursConfig} />
            <p style={{ color: '#b3b3b3', fontWeight: 400, textAlign: 'center', marginTop: 5 }}>* 自購買日起</p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={5}>
            <div onClick={(e) => setShowUsageToolTip(!showUsageToolTip)}>
              <Popconfirm placement="top" icon={null} title={usageRateText} visible={showUsageToolTip}>
                <div style={{ textAlign: 'center', minHeight: 60 }}>
                  <Badge color="volcano" text="持續使用率" />
                  <ExclamationCircleOutlined style={{ color: '#faad14', marginLeft: 5 }} />
                  {Number(usageRateData?.usedRate) * 100 >= 70 ? (
                    <div style={{ color: '#8bc34a' }}>{'持續使用率 > 70% 良好'} </div>
                  ) : (
                    ''
                  )}
                </div>
              </Popconfirm>
            </div>

            <RingProgress style={{ display: 'block', margin: '0 auto' }} {...usageRateConfig} />
            <p style={{ color: '#b3b3b3', fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
              * 自購買日起
              <br />
              <span style={{ fontSize: 12 }}>連續使用4小時才列入計算</span>
            </p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6} xl={5}>
            <div onClick={(e) => setShowLeakToolTip(!showLeakToolTip)}>
              <Popconfirm placement="top" icon={null} title={leakVentText} visible={showLeakToolTip}>
                <div style={{ textAlign: 'center', minHeight: 60 }}>
                  <Badge color="geekblue" text="3天漏氣平均值" />
                  <ExclamationCircleOutlined style={{ color: '#faad14', marginLeft: 5 }} />
                  {Number(usageRateData?.threeDaysAverageLeakVent) < 24 ? (
                    <div style={{ color: '#8bc34a' }}>{'平均值 < 24L/分 良好'} </div>
                  ) : (
                    ''
                  )}
                </div>
              </Popconfirm>
            </div>
            <RingProgress style={{ display: 'block', margin: '0 auto' }} {...threeDaysAverageLeakVentConfig} />
            <p style={{ color: '#b3b3b3', fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
              ({usageRateData.threeDayCacheDate1 ? moment(usageRateData.threeDayCacheDate1).format('MM/DD') : ''} ~{' '}
              {usageRateData.threeDayCacheDate2 ? moment(usageRateData.threeDayCacheDate2).format('MM/DD') : ''})
            </p>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6} xl={5}>
            <div onClick={(e) => setShowAHIToolTip(!showAHIToolTip)}>
              <Popconfirm placement="top" icon={null} title={AHIText} visible={showAHIToolTip}>
                <div style={{ textAlign: 'center', minHeight: 60 }}>
                  <Badge color="cyan" text="3天 AHI 平均值" />
                  <ExclamationCircleOutlined style={{ color: '#faad14', marginLeft: 5 }} />
                  {Number(usageRateData?.threeDaysAverageAHI) < 5 ? (
                    <div style={{ color: '#8bc34a' }}>{'平均值 < 5小時 良好'} </div>
                  ) : (
                    ''
                  )}
                </div>
              </Popconfirm>
            </div>
            <RingProgress style={{ display: 'block', margin: '0 auto' }} {...threeDaysAverageAhiConfig} />
            <p style={{ color: '#b3b3b3', fontWeight: 400, textAlign: 'center', marginTop: 5 }}>
              ({usageRateData.threeDayCacheDate1 ? moment(usageRateData.threeDayCacheDate1).format('MM/DD') : ''} ~{' '}
              {usageRateData.threeDayCacheDate2 ? moment(usageRateData.threeDayCacheDate2).format('MM/DD') : ''})
            </p>
          </Col>
        </Row>
      )}
      <Divider>歷史紀錄</Divider>
      <Row justify="space-between">
        <Col span={8} offset={8}>
          <h3 style={{ textAlign: 'center' }}>{moment(searchMonth).format('YYYY 年 MM 月')}</h3>
        </Col>
        <Col>
          <DatePicker picker="month" defaultValue={moment()} onChange={onChangeMonth} />
        </Col>
      </Row>
      {noUsageData ? (
        <Empty />
      ) : (
        <Column
          data={historyData}
          xField={'day'}
          yField={'hours'}
          columnWidthRatio={0.5}
          height={140}
          seriesField={'type'}
          meta={{
            day: { alias: '日' },
            hours: { alias: '時數' },
          }}
          color={({ type, type1 }) => {
            return type === '大於4小時' ? '#8bc34a' : '#eb2f96';
          }}
          tooltip={{
            formatter: (datum: any) => {
              return { name: '時數', value: datum.hours };
            },
          }}
        />
      )}
    </Card>
  );
};

export default DashboardCard;
