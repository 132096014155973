import React from 'react';
import { Modal, Form, Input, DatePicker, Result } from 'antd';

interface CollectionCreateFormProps {
  processing: boolean;
  visible: boolean;
  changeCCIDSuccess: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
}
const CCIDModifyModalContainer: React.FC<CollectionCreateFormProps> = (props) => {
  const { processing, visible, changeCCIDSuccess, onSubmit, onCancel } = props;
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
    colon: false,
  };

  return (
    <Modal
      title="變更信用卡號"
      className="modifyModal"
      visible={visible}
      width={382}
      closable={false}
      okText={changeCCIDSuccess ? '關閉' : '變更'}
      cancelText={changeCCIDSuccess}
      cancelButtonProps={{ style: { display: changeCCIDSuccess ? 'none' : 'inline-block' } }}
      onCancel={onCancel}
      onOk={() => {
        if (!changeCCIDSuccess) {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        } else if (changeCCIDSuccess) {
          onCancel();
        }
      }}
    >
      {changeCCIDSuccess ? (
        <Result status="success" title="信用卡號已變更" />
      ) : (
        <Form form={form} layout="horizontal" {...formItemLayout}>
          <Form.Item label="信用卡號" name="cardNumber" rules={[{ required: true, message: '請輸入信用卡號' }]}>
            <Input placeholder="請輸入信用卡號" disabled={processing} />
          </Form.Item>
          <Form.Item label="有效期" name="expiredDate" rules={[{ required: true, message: '請輸入有效期' }]}>
            <DatePicker.MonthPicker
              style={{ width: '100%' }}
              placeholder="請輸入有效期"
              format="MM月 YYYY年"
              disabled={processing}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default CCIDModifyModalContainer;
